import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import Loader from "./loader";
import "./Appdash.css";
import { Sidebar } from "./Sidebar";

function Homepage() {
  const isAuthenticated = localStorage.getItem("uuid");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [notifications, setNotifications] = useState("");
  const [duree, setDuree] = useState("");
  const [typeCompte, setTypeCompte] = useState("");
  const [errormsg, setErrormsg] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // SPINNER
  setTimeout(() => {
    setIsLoading(false);
  }, 2000);
  // SPINNER

  const [msg, setMsg] = useState("");
  const ONE_DAY = 24 * 60 * 60 * 1000;

  async function submit() {
    setLoading(true);
    // console.log(duree, typeCompte)
    if (duree === "") {
      setErrormsg("Veuillez choisir un plan d'abonnement ⏳");
      setTimeout(() => {
        setErrormsg("");
        setLoading(false);
      }, 2000);
      return null;
    }
    if (typeCompte === "") {
      setErrormsg("Veuillez choisir le type de compte 🤩");
      setTimeout(() => {
        setErrormsg("");
        setLoading(false);
      }, 2000);
      return null;
    }

    if (duree !== "" && typeCompte !== "") {
      var price = "";
      //  var duree = "";
      var expire = "";

      if (duree === "01") {
        price = 2500;
        expire = Date.now() + 30 * ONE_DAY;
      }

      if (duree === "03") {
        price = 7500;
        expire = Date.now() + 90 * ONE_DAY;
      }

      if (duree === "06") {
        price = 15000;
        expire = Date.now() + 180 * ONE_DAY;
      }
      if (duree === "12") {
        price = 30000;
        expire = Date.now() + 365 * ONE_DAY;
      }
      //Visa payment
      //  Abonnement Expire
      // const now = new Date(Number(expire)).toLocaleString("fr-FR", {
      //   timeZoneName: "short",
      // });
      // console.log(now);
      //  console.log(expire);
      // Abonnement Expire

      axios
        .get(process.env.React_App_base_url + `users/` + userID, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Content-Type": "application/json",
            Authorization: `Bearer ${isAuthenticated}`,
          },
        })
        .then(async (res) => {
          const pseudo = res.data.data.pseudo;
          const email = res.data.data.email;
          const phone = res.data.data.phone;

          if (email) {
            // console.log(expire);
            //  const axios = require("axios");
            let data = JSON.stringify({
              pseudo: pseudo,
              userEmail: email,
              userPhone: phone,
              productname: "GoFLIX",
              abDure: duree,
              ExpireDate: expire,
              forme: typeCompte,
              montant: price,
              flixEmail: "*****",
              flixPass: "*****",
              profilName: "Go2412",
              profilPass: "*****",
              payRef: "Go-2317" + Date.now(),
              paymentStatus: "PENDING",
              paymentMode: "All",
            });

            let config = {
              method: "post",
              maxBodyLength: Infinity,
              url: process.env.React_App_base_url + `abonnements/visacard`,
              headers: {
                Authorization: `Bearer ${isAuthenticated}`,
                "Content-Type": "application/json",
              },
              data: data,
            };

            axios
              .request(config)
              .then((res) => {
                //  console.log(JSON.stringify(response.data));
                // console.log(res);
                let respData = res.data.message;
                let PaymentUrl = res.data.paymentRediref.url;
                let Payment_Id = res.data.paymentId;
                let Payment_Ref = res.data.paymentRef;
                // const NewData = JSON.parse(res.config.data);
                if (respData) {
                  setMsg(respData);
                  setLoading(true);

                  let commandeID = Payment_Id;
                  localStorage.setItem("odr", commandeID);
                  setTimeout(() => {
                    window.location.href = PaymentUrl;
                  }, 3000);
                  // let commandePrice = price;
                  // localStorage.setItem("theprice", commandePrice);
                  // console.log(PaymentUrl);
                }
                if (!email) {
                  setErrormsg(
                    "*Erreur de connexion. Patientez et réessayer le paiement !"
                  );
                  setTimeout(() => {
                    setErrormsg("");
                  }, 3000);
                  //navigate("./dashboard/updateplan");
                  return null;
                }
              })
              .catch((error) => {
                //  console.log(error);
              });
          }
        });
    }
  }

  // function submit(){
  //   console.log(typeCompte, duree);
  // }

  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("uuid") !== null
  );

  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated) {
        let StateId = atob(localStorage.getItem("isLoggedIn"));
        // console.log(StateId, isAuthenticated);

        if (StateId) {
          axios
            .get(process.env.React_App_base_url + `users/` + StateId, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Content-Type": "application/json",
                Authorization: `Bearer ${isAuthenticated}`,
              },
            })
            .then((res) => {
              //  console.log(res.data.data);
              const userData = res.data.data;
              setUserName(userData.pseudo);
              setNotifications(userData.notify);
              //  console.log(res.dada.data.pseudo)
              let ActivationCompte = res.data.data.isActive;

              if (ActivationCompte !== true) {
                navigate(".././login");
              }

              //console.log(res.data);
              // let getOpt = res.data.NewData.codeOtp;
              // console.log(getOpt);
            });
        }
      }
    }, 1000);
  }, []);

  //  User inactivity

  function Userinactivity() {
    var time;
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(handleLogout, 100000000);
    }
  }

  Userinactivity();

  //Check if user Login
  let TokenStatic = "HDHYI67!UY~@LJHF9H9W6L4";

  //Check if user Login

  if (!isAuthenticated) {
    navigate("../login", { replace: true });
  }

  if (isAuthenticated) {
    let CheckUserlogged = localStorage.getItem("reactjs");

    if (atob(CheckUserlogged) !== TokenStatic) {
      localStorage.clear();
      navigate("../login", { replace: true });
    }
  }

  function checkUserExist() {
    if (!loggedIn) {
      // if the user is not logged in, redirect to the login page
      window.location.href = "../login";
      return null;
    }
  }

  useEffect(() => {
    checkUserExist();
  }, []);
  //Check if user Login

  const handleLogout = () => {
    // clear the token from local storage and set the user as logged out
    // localStorage.removeItem("uuid");
    localStorage.clear();
    setLoggedIn(false);
    window.location.href = "../login";
    return null;
  };

  return (
    <>
      {isLoading ? (
        <div className="mx-auto my-auto w-24">
          <Loader />
        </div>
      ) : (
        <div className="bg-gray-900 text-gray-100">
          <link
            href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp"
            rel="stylesheet"
          />

          <div class="bg-gray-900 min-h-screen">
            <div class="fixed bg-white text-blue-800 px-10 py-1 z-10 w-full">
              <div class="flex items-center justify-between py-2 text-5x1">
                <div class="font-bold text-blue-900 text-xl">
                  <span className="hover:text-gray-900 text-4xl font-bold text-pink-500">
                    {" "}
                    GoFLIX .
                  </span>
                  {/* <span class="text-gray-900 mx-auto text-center">Tableau de Bord</span> */}
                </div>
                <div class=" flex items-center text-gray-500">
                  {/* <span class="material-icons-outlined p-2">search</span> */}
                  <a
                    href="./mycompte"
                    title="Voir les identifiants Netflix reçus"
                    class="name material-icons-outlined p-2"
                  >
                    notifications{" "}
                  </a>
                  <div class="inline-flex items-center -ml-4 px-1.5 py-0.5 border-2 border-white rounded-full text-xs font-semibold leading-4 bg-red-500 text-white">
                    {notifications}
                  </div>
                  <img
                    className="bg-center bg-cover bg-no-repeat rounded-full border-2 inline-block h-12 w-12 ml-2"
                    src=".././enfant _digital.jpeg"
                    alt=""
                  />
                  <span class="">{userName}</span>
                </div>
                {/* <!-- Sidebar --> */}
              </div>
            </div>

            <div class=" flex flex-row pt-24  px-10 pb-4">
              <div class="myMenu w-2/12 mr-6">
                <div class="bg-white rounded-xl shadow-lg mb-6 px-6 py-4">
                  <a
                    href="./homepage"
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      dashboard
                    </span>
                    Home
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </a>
                  <a
                    href="./mycompte"
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      tune
                    </span>
                    Mon compte
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </a>
                  <a
                    href="./historiques"
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      file_copy
                    </span>
                    Historiques
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </a>
                </div>
                <div class="myMenu bg-white rounded-xl shadow-lg mb-6 px-6 py-4">
                  <a
                    href="./profil"
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      face
                    </span>
                    Profil
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </a>
                  {/* <a
                href=""
                class="inline-block text-gray-600 hover:text-black my-4 w-full"
              >
                <span class="material-icons-outlined float-left pr-2">
                  settings
                </span>
                Paramètres
                <span class="material-icons-outlined float-right">
                  keyboard_arrow_right
                </span>
              </a> */}
                  <button
                    onClick={handleLogout}
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      power_settings_new
                    </span>
                    Déconnexion
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </button>
                </div>
                <img class="rounded-lg " src=".././Elearning.jpg" alt="" />
                {/* <img class="rounded-lg   mt-4 " src=".././pub.png" alt="" /> */}
                <img class="rounded-lg   mt-4 " src=".././bb.png" alt="" />
              </div>

              <div class="w-10/12">
                {/* <section class="flex flex-col mt-2 text-pink-500 mb-8">
              <p class="font-semibold animate-pulse text-2xl md:text-3xl text-center">
                Pourquoi +De 250,000 personnes utilisent NETFLIX ?
              </p>
              <div class="mt-10 grid grid-cols-2 lg:grid-cols-4 gap-y-5 lg:gap-y-0 gap-x-5 place-items-center w-full mx-auto max-w-7xl px-5">
                <div class="flex flex-col justify-center items-center bg-[#FFF6F3] px-4 h-[126px] w-[100%] md:w-[281px] md:h-[192px] rounded-lg justify-self-center">
                  <div class="flex flex-row justify-center items-center">
                    <svg
                      class="w-[35px] h-[35px] md:w-[50px] md:h-[50px]"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M39.37 18.432c0 3.058-.906 5.862-2.466 8.203a14.728 14.728 0 0 1-10.079 6.367c-.717.127-1.455.19-2.214.19-.759 0-1.497-.063-2.214-.19a14.728 14.728 0 0 1-10.078-6.368 14.692 14.692 0 0 1-2.467-8.202c0-8.16 6.6-14.76 14.76-14.76s14.759 6.6 14.759 14.76Z"
                        stroke="#FF6D42"
                        stroke-width="3.473"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="m44.712 38.17-3.431.83a2.063 2.063 0 0 0-1.539 1.572l-.728 3.122c-.09.384-.281.734-.554 1.012a2.068 2.068 0 0 1-.992.564c-.375.09-.768.073-1.134-.052a2.078 2.078 0 0 1-.938-.653l-9.92-11.64-9.92 11.661a2.078 2.078 0 0 1-.938.653 2.038 2.038 0 0 1-1.134.052 2.067 2.067 0 0 1-.992-.563 2.137 2.137 0 0 1-.554-1.012l-.728-3.123a2.13 2.13 0 0 0-.55-1.01 2.06 2.06 0 0 0-.988-.562L6.24 38.19a2.073 2.073 0 0 1-.956-.533 2.14 2.14 0 0 1-.563-.953 2.175 2.175 0 0 1-.015-1.113c.091-.366.276-.7.536-.97l8.11-8.284a14.672 14.672 0 0 0 4.307 4.281 14.34 14.34 0 0 0 5.634 2.134 12.29 12.29 0 0 0 2.183.191c.749 0 1.477-.063 2.184-.19 4.138-.617 7.694-3.017 9.94-6.416l8.11 8.285c1.144 1.147.583 3.165-.998 3.547Zm-18.03-26.532 1.227 2.507c.167.34.603.68.998.743l2.226.383c1.414.233 1.747 1.296.727 2.336l-1.726 1.764c-.29.297-.457.87-.353 1.295l.499 2.188c.395 1.721-.5 2.4-1.996 1.487l-2.08-1.253a1.434 1.434 0 0 0-1.372 0l-2.08 1.253c-1.497.892-2.392.234-1.996-1.487l.499-2.188c.083-.403-.063-.998-.354-1.295l-1.726-1.764c-1.019-1.04-.686-2.081.728-2.336l2.225-.383c.375-.063.811-.403.977-.743l1.227-2.507c.604-1.36 1.685-1.36 2.35 0Z"
                        stroke="#FF6D42"
                        stroke-width="3.473"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <p class="font-bold text-3xl sm:text-4xl lg:text-5xl leading-9 text-primary ml-2">
                      HD+
                    </p>
                  </div>
                  <p class="font-medium text-base sm:text-lg leading-6 mt-3 md:mt-6 text-center">
                    Accès en temps réel
                  </p>
                </div>
                <div class="flex flex-col justify-center items-center bg-[#FFF6F3] px-4 h-[126px] w-[100%] md:w-[281px] md:h-[192px] rounded-lg justify-self-center">
                  <div class="flex flex-row justify-center items-center">
                    <svg
                      class="w-[35px] h-[35px] md:w-[50px] md:h-[50px]"
                      viewBox="0 0 51 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#a)">
                        <path
                          d="m26.91 5.776 4.483 10.683a1.544 1.544 0 0 0 1.287.942l11.474.992a1.544 1.544 0 0 1 .876 2.715L36.325 28.7a1.559 1.559 0 0 0-.49 1.523l2.61 11.296a1.544 1.544 0 0 1-2.295 1.677l-9.86-5.982a1.53 1.53 0 0 0-1.59 0l-9.861 5.982a1.544 1.544 0 0 1-2.295-1.677l2.609-11.296a1.56 1.56 0 0 0-.49-1.523l-8.705-7.593a1.544 1.544 0 0 1 .876-2.715l11.474-.992a1.544 1.544 0 0 0 1.287-.942l4.483-10.683a1.544 1.544 0 0 1 2.833 0Z"
                          stroke="#FF6D42"
                          stroke-width="4.341"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="a">
                          <path fill="#fff" d="M.8.2h49.4v49.4H.8z"></path>
                        </clipPath>
                      </defs>
                    </svg>
                    <p class="font-bold text-3xl sm:text-4xl lg:text-5xl leading-9 text-primary ml-2">
                      4.9
                    </p>
                  </div>
                  <p class="font-medium text-base sm:text-lg leading-6 mt-3 md:mt-6 text-center">
                    Evaluation des experts
                  </p>
                </div>
                <div class="flex flex-col justify-center items-center bg-[#FFF6F3] px-4 h-[126px] w-[100%] md:w-[281px] md:h-[192px] rounded-lg justify-self-center">
                  <div class="flex flex-row justify-center items-center">
                    <svg
                      class="w-[35px] h-[35px] md:w-[50px] md:h-[50px]"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        clip-path="url(#a)"
                        stroke="#FF6D42"
                        stroke-width="3.473"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M10.811 39.091c-1.775-1.775-.598-5.505-1.5-7.69-.939-2.255-4.377-4.089-4.377-6.5 0-2.413 3.438-4.246 4.376-6.502.903-2.182-.274-5.914 1.501-7.69 1.776-1.775 5.508-.598 7.69-1.5 2.266-.939 4.09-4.377 6.501-4.377 2.412 0 4.246 3.438 6.501 4.376 2.185.903 5.915-.274 7.69 1.501 1.776 1.776.598 5.506 1.502 7.69.937 2.266 4.376 4.09 4.376 6.501 0 2.412-3.439 4.246-4.377 6.501-.903 2.185.274 5.915-1.5 7.69-1.776 1.776-5.506.598-7.69 1.501-2.256.938-4.09 4.377-6.502 4.377s-4.245-3.439-6.5-4.377c-2.183-.903-5.915.275-7.69-1.5Z"></path>
                        <path d="m17.281 26.444 4.632 4.631L32.718 20.27"></path>
                      </g>
                      <defs>
                        <clipPath id="a">
                          <path fill="#fff" d="M.3.2h49.4v49.4H.3z"></path>
                        </clipPath>
                      </defs>
                    </svg>
                    <p class="font-bold text-3xl sm:text-4xl lg:text-5xl leading-9 text-primary ml-2">
                      8900+
                    </p>
                  </div>
                  <p class="font-medium text-base sm:text-lg leading-6 mt-3 md:mt-6 text-center">
                    Sessions ouvertes/heure
                  </p>
                </div>
                <div class="flex flex-col justify-center items-center bg-[#FFF6F3] px-4 h-[126px] w-[100%] md:w-[281px] md:h-[192px] rounded-lg justify-self-center">
                  <div class="flex flex-row justify-center items-center">
                    <svg
                      class="w-[35px] h-[35px] md:w-[50px] md:h-[50px]"
                      viewBox="0 0 51 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M45.571 12.006 27.046 30.531l-7.719-7.718L5.434 36.706"
                        stroke="#FF6D42"
                        stroke-width="4.341"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M45.569 24.356v-12.35h-12.35"
                        stroke="#FF6D42"
                        stroke-width="4.341"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                    <p class="font-bold text-3xl sm:text-4xl lg:text-5xl leading-9 text-primary ml-2">
                      1.5M+
                    </p>
                  </div>
                  <p class="font-medium text-base sm:text-lg leading-6 mt-3 md:mt-6 text-center">
                    Téléchargements
                  </p>
                </div>
              </div>
            </section> */}
                {/* <div class="flex flex-row">
              <div class="bg-no-repeat bg-red-200 border border-red-300 rounded-xl w-7/12 mr-2 p-6 bg-url(https://previews.dropbox.com/p/thumb/AAvyFru8elv-S19NMGkQcztLLpDd6Y6VVVMqKhwISfNEpqV59iR5sJaPD4VTrz8ExV7WU9ryYPIUW8Gk2JmEm03OLBE2zAeQ3i7sjFx80O-7skVlsmlm0qRT0n7z9t07jU_E9KafA9l4rz68MsaZPazbDKBdcvEEEQPPc3TmZDsIhes1U-Z0YsH0uc2RSqEb0b83A1GNRo86e-8TbEoNqyX0gxBG-14Tawn0sZWLo5Iv96X-x10kVauME-Mc9HGS5G4h_26P2oHhiZ3SEgj6jW0KlEnsh2H_yTego0grbhdcN1Yjd_rLpyHUt5XhXHJwoqyJ_ylwvZD9-dRLgi_fM_7j/p.png?fv_content=true&size_mode=5); background-position: 90% center;">
                <img
                  className="h-96 rounded-lg w-full"
                  src=".././play.webp"
                  alt=""
                />
              </div>

              <div class="bg-no-repeat bg-orange-200 border border-orange-300 rounded-xl w-5/12 ml-2 p-6  bg-url(https://previews.dropbox.com/p/thumb/AAuwpqWfUgs9aC5lRoM_f-yi7OPV4txbpW1makBEj5l21sDbEGYsrC9sb6bwUFXTSsekeka5xb7_IHCdyM4p9XCUaoUjpaTSlKK99S_k4L5PIspjqKkiWoaUYiAeQIdnaUvZJlgAGVUEJoy-1PA9i6Jj0GHQTrF_h9MVEnCyPQ-kg4_p7kZ8Yk0TMTL7XDx4jGJFkz75geOdOklKT3GqY9U9JtxxvRRyo1Un8hOObbWQBS1eYE-MowAI5rNqHCE_e-44yXKY6AKJocLPXz_U4xp87K4mVGehFKC6dgk_i5Ur7gspuD7gRBDvd0sanJ9Ybr_6s2hZhrpad-2WFwWqSNkh/p.png?fv_content=true&size_mode=5); background-position: 100% 40%;">
                <p class="text-5xl text-indigo-900">
                  Inbox <br />
                  <strong>23</strong>
                </p>
                <a
                  href=""
                  class="bg-orange-300 text-xl text-white underline hover:no-underline inline-block rounded-full mt-12 px-8 py-2"
                >
                  <strong>See messages</strong>
                </a>
              </div>
            </div> */}
                <div class="  flex flex-row h-auto mt-6">
                  <div class="plan w-full p-6 rounded-lg shadow-xl sm:w-1/2 bg-gradient-to-br from-blue-600 to-purple-600 sm:p-8">
                    <div class="flex flex-col items-start justify-between gap-4 mb-6 lg:flex-row">
                      <div>
                        <h3 class="text-2xl  text-orange-300 font-bold jakarta sm:text-4xl">
                          Abonnement
                        </h3>
                      </div>
                      <span class="order-first inline-block px-3 py-1 text-xs font-semibold tracking-wider text-white uppercase bg-black rounded-full lg:order-none bg-opacity-20">
                        NETFLIX
                      </span>
                    </div>
                    <div class="isolate mt-8 flex items-center justify-center -space-x-2 overflow-hidden">
                      <img
                        class="relative z-30 inline-block h-10 w-10 rounded-full animate-pulse  ring-2 ring-white"
                        src=".././1.jpeg"
                        alt=""
                      />
                      <img
                        class="relative z-20 inline-block h-10 w-10 rounded-full ring-2 ring-white"
                        src=".././im1.jpg"
                        alt=""
                      />
                      <img
                        class="relative z-10 inline-block h-10 w-10 animate-pulse  rounded-full ring-2 ring-white"
                        src=".././Dev_Vide.png"
                        alt=""
                      />

                      <img
                        class="relative z-0 inline-block h-10 w-10 rounded-full ring-2 ring-white"
                        src=".././Devant_Portable.png"
                        alt=""
                      />
                      <span class="!ml-2 font-bold italic text-gray-100">
                        Famille GoFLIX
                      </span>
                    </div>
                    <div class="mb-4 space-x-2">
                      <span class="text-4xl font-bold text-white">
                        FCFA2.500/mois
                      </span>
                      <span class="myMenu text-2xl text-indigo-100 line-through">
                        FCFA8.500/mois
                      </span>
                    </div>
                    <div class="mb-4">
                      <label class="block mb-1 text-orange-300">
                        PLAN DE SOUSCRIPTION
                      </label>
                      <div class="flex items-center text-pink-300 space-x-2">
                        <input
                          type="radio"
                          name={duree}
                          onChange={(e) => {
                            setDuree(e.target.value);
                          }}
                          id="01"
                          value="01"
                          class="focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <label for="01">01 mois</label>
                        <input
                          type="radio"
                          name={duree}
                          onChange={(e) => {
                            setDuree(e.target.value);
                          }}
                          id="06"
                          value="06"
                          class="focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <label for="06">06 mois</label>
                        <input
                          type="radio"
                          name={duree}
                          onChange={(e) => {
                            setDuree(e.target.value);
                          }}
                          id="12"
                          value="12"
                          class="focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <label for="12">12 mois</label>
                      </div>
                      <select
                        id="underline_select"
                        value={typeCompte}
                        onChange={(e) => {
                          setTypeCompte(e.target.value);
                        }}
                        class="block py-2.5 px-0 w-full text-sm text-orange-300 border-b rounded-lg bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                      >
                        <option selected className="p-4">
                          Selectionnez le type de compte
                        </option>
                        <option value="forme">Moi même</option>
                        <option value="other">Pour un proche</option>
                      </select>
                    </div>
                    <ul class="mb-6 space-y-2 text-indigo-100">
                      <li class="flex items-center gap-1.5">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="flex-shrink-0 w-5 h-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class=""> Abonnement GoFLIX famille</span>
                      </li>
                      <li class="flex items-center gap-1.5">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="flex-shrink-0 w-5 h-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="">4K, HD</span>
                      </li>
                      <li class="flex items-center gap-1.5">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="flex-shrink-0 w-5 h-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                            class=""
                          ></path>
                        </svg>
                        <span class="">Identifiants d'accès</span>
                      </li>
                      <li class="flex items-center gap-1.5">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="flex-shrink-0 w-5 h-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="">Téléchargements de films...</span>
                      </li>
                      <li class="flex items-center gap-1.5">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="flex-shrink-0 w-5 h-5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                        <span class="">Mobile, PC, TV</span>
                      </li>
                    </ul>
                    <p className="text-orange-300 p-2 text-xl text-center">
                      {msg}
                    </p>
                    <p class="text-center p-2 text-2xl text-orange-400">
                      {errormsg}{" "}
                    </p>
                    <button
                      type="submit"
                      // onClick={onSubmit}
                      onClick={submit}
                      enable={loading}
                      class="block px-8 py-3 text-sm font-semibold text-center text-white transition duration-100 bg-white rounded-lg outline-none bg-opacity-20 hover:bg-opacity-30 md:text-base"
                    >
                      {loading ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 animate-spin mx-auto"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                          />
                        </svg>
                      ) : (
                        "Achetez maintenant"
                      )}
                    </button>
                  </div>
                  <div class="myMenu p-4 max-w-xl mx-auto">
                    <h2 class="font-heading mb-8 text-3xl font-bold lg:text-4xl">
                      Comment ça marche ?
                    </h2>

                    <div class="flex"></div>

                    <div class="flex">
                      <div class="mr-4 flex flex-col items-center">
                        <div>
                          <div class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-pink-500">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="h-6 w-6 text-pink-500 dark:text-slate-200"
                            >
                              <path d="M12 5l0 14"></path>
                              <path d="M18 13l-6 6"></path>
                              <path d="M6 13l6 6"></path>
                            </svg>
                          </div>
                        </div>
                        <div class="h-full w-px bg-gray-300 dark:bg-slate-500"></div>
                      </div>
                      <div class="pt-1 pb-8">
                        <p class="mb-2 text-xl font-bold text-pink-500 dark:text-slate-300">
                          Etape 01
                        </p>
                        <p class="text-gray-200 dark:text-slate-400">
                          Souscrire à l'abonnement et recevoir vos identifiants
                          dans votre espace Mon Compte, par mail et par whatSapp
                        </p>
                      </div>
                    </div>

                    <div class="flex">
                      <div class="mr-4 flex flex-col items-center">
                        <div>
                          <div class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-pink-500">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="h-6 w-6 text-pink-500 dark:text-slate-200"
                            >
                              <path d="M12 5l0 14"></path>
                              <path d="M18 13l-6 6"></path>
                              <path d="M6 13l6 6"></path>
                            </svg>
                          </div>
                        </div>
                        <div class="h-full w-px bg-gray-300 dark:bg-slate-500"></div>
                      </div>
                      <div class="pt-1 pb-8">
                        <p class="mb-2 text-xl font-bold text-pink-500 dark:text-slate-300">
                          Etape 02
                        </p>
                        <p class="text-gray-200 dark:text-slate-400 text-justify">
                          Une fois le paiement réussi, rendez-vous sur la page «
                          Mon compte » pour afficher le compte et le mot de
                          passe achetés. Connectez-vous au site officiel de
                          l'abonnement que vous avez acheté en utilisant le
                          compte et le mot de passe.
                        </p>
                      </div>
                    </div>

                    <div class="flex">
                      <div class="mr-4 flex flex-col items-center">
                        <div>
                          <div class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-pink-500 bg-PINK-500">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="h-6 w-6 text-white dark:text-slate-200"
                            >
                              <path d="M5 12l5 5l10 -10"></path>
                            </svg>
                          </div>
                        </div>
                      </div>
                      <div class="pt-1 ">
                        <p class="mb-2 text-xl font-bold text-pink-500 dark:text-slate-300">
                          Etape 03
                        </p>
                        <p class="mb-2 text-sm text-justify font-semibold text-pink-500 dark:text-slate-300">
                          Tout est prêt ! NB: Vous ne pouvez pas vous connecter
                          à plusieurs appareils au même moment au risque de vous
                          voir bloquer. Votre profil est personnel et veuillez
                          vous déconnecter sur votre appareil principal avant de
                          vous connecter à un autre. Dans un instant vous aurez
                          vos identifiants par mail et whatsapp Merci pour votre
                          compréhension.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="myMenu bg-white rounded-xl shadow-lg px-6 py-4 w-4/12">
                    <img className=" w-auto" src=".././Ca.jpg" alt="" />
                    <img className=" w-auto" src=".././boy.webp" alt="" />
                    <img className=" w-auto" src=".././Cata.jpg" alt="" />
                    <img className=" w-auto" src=".././im1.jpg" alt="" />
                    <img className=" w-auto" src=".././play.webp" alt="" />
                    <img
                      className=" w-auto"
                      src=".././gettyimages-530995179.jpg"
                      alt=""
                    />
                    <img className=" w-auto" src=".././1.jpeg" alt="" />

                    <img className=" w-auto" src=".././netflix.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center text-gray-100 bg-pink-500 font-medium ">
            <span className="MenuSide fixed text-orange-300 -mt-32 ">
              <Sidebar />
            </span>
            &copy;2023 CopyRight. Tout droit réservé GoFLIX.
          </div>
        </div>
      )}
    </>
  );
}

export default Homepage;
