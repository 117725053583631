import "./App.css";
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

function Footer() {
  return (
    <footer class="flex flex-col space-y-10  justify-center">
      <div class="flex space-x-5 space-y-5 flex-wrap justify-center items-baseline">
        <div class="mx-auto max-w-7xl sm:px-6 lg:px-8 mt-20">
          <div class="relative isolate overflow-hidden bg-white px-6 py-20 text-center sm:rounded-3xl sm:border sm:border-gray-100 sm:px-16 sm:shadow-sm">
            <h2 class="mx-auto max-w-2xl text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Rejoignez notre communauté dès aujourd'hui !
            </h2>

            <h3 class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-500">
              Inscrivez-vous pour bénéficier d'un accès exclusif aux événements,
              aux ressources et à bien d'autres choses encore.
            </h3>

            <div class="mt-8 flex items-center justify-center gap-x-6">
              <a
                class="inline-flex items-center justify-center gap-2 rounded-xl bg-pink-500 px-4 py-3 text-sm font-semibold text-white shadow-sm transition-all duration-150 hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                href="https://t.me/+BhPaRqaNL5YyYzA0" target="_blank"
              >
                Explorer le forum
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>

            <svg
              viewBox="0 0 1024 1024"
              class="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-x-1/2 [mask-image:radial-gradient(closest-side,white,transparent)]"
              aria-hidden="true"
            >
              <circle
                cx="512"
                cy="512"
                r="512"
                fill="url(#827591b1-ce8c-4110-b064-7cb85a0b1217)"
                fill-opacity="0.7"
              ></circle>
              <defs>
                <radialGradient id="827591b1-ce8c-4110-b064-7cb85a0b1217">
                  <stop stop-color="#3b82f6"></stop>
                  <stop offset="1" stop-color="#1d4ed8"></stop>
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>
        {/* <button class="relative bg-blue-700 hover:bg-blue-800 duration-300 py-2 px-4 text-blue-100 rounded">
          24H CHRONO
          <span class="absolute bg-blue-500 text-blue-100 px-2 py-1 text-xs font-bold rounded-full -top-3 -right-3">
            Serie+
          </span>
        </button>
        <button class="relative bg-pink-500 hover:bg-blue-800 duration-300 py-2 px-4 text-blue-100 rounded">
          CODE CRIME
          <span class="absolute bg-pink-600 text-gray-100 px-2 py-1 text-xs font-bold rounded-full -top-3 -right-3">
            Serie+
          </span>
        </button>
        <button class="relative bg-green-500 hover:bg-blue-800 duration-300 py-2 px-4 text-blue-100 rounded">
          LES DEUX PAPA
          <span class="absolute bg-green-700 text-green-100 px-2 py-1 text-xs font-bold rounded-full -top-3 -right-3">
            FILM+
          </span>
        </button>
        <button class="relative bg-red-700 hover:bg-blue-800 duration-300 py-2 px-4 text-blue-100 rounded">
          AU PLAISIR DE SE FAIT TRAHIR
          <span class="absolute bg-red-600 text-red-100 px-2 py-1 text-xs font-bold rounded-full -top-3 -right-3">
            FILM+
          </span>
        </button>
        <button class="relative bg-yellow-700 hover:bg-blue-800 duration-300 py-2 px-4 text-blue-100 rounded">
          PRINCESSE BARBY
          <span class="absolute bg-yellow-500 text-yellow-900 px-2 py-1 text-xs font-bold rounded-full -top-3 -right-3">
            DESSIN ANIME+
          </span>
        </button>
        <button class="relative bg-teal-900 hover:bg-blue-800 duration-300 py-2 px-4 text-blue-100 rounded">
          NAROTO
          <span class="absolute bg-teal-300 text-teal-900 px-2 py-1 text-xs font-bold rounded-full -top-3 -right-3">
            KIDS+
          </span>
        </button>
        <button class="relative bg-gray-700 hover:bg-blue-800 duration-300 py-2 px-4 text-blue-100 rounded">
          ZINEDINE 10
          <span class="absolute bg-gray-200 text-gray-900 px-2 py-1 text-xs font-bold rounded-full -top-3 -right-3">
            EMISSION TV+
          </span>
        </button>
        <button class="relative bg-blue-700 hover:bg-blue-800 duration-300 py-2 px-4 text-blue-100 rounded">
         ENGLISH CLASS
          <span class="absolute bg-gray-900 text-gray-100 px-2 py-1 text-xs font-bold rounded-full -top-3 -right-3">
            EMISSION TV+
          </span>
        </button> */}
      </div>

      <div class="text-center text-gray-100 bg-pink-500 font-medium ">
        &copy;2023 CopyRight. Tout droit réservé GoFLIX.
      </div>
    </footer>
  );
}

export default Footer;
