import "./App.css";
import Header from "./Header";
import Series from "./Series";
import Footer from "./Footer";
import { useState } from "react";
import Intros from "./Intros";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
// import axios from "axios";
import InscriptionEmail from "./SendEmail";

function Signup() {
  const [value, setValue] = useState();
  const [loading, setLoading] = useState(false);
  const [errormsg, setErrormsg] = useState("");
  const [pseudo, setPseudo] = useState("");
  const [email, setEmail] = useState("");
  const [isActive, setIsActive] = useState("");
  const [passwordConfirme, setPasswordConfirme] = useState("");
  const [password, setPassword] = useState("");
  const [ExpireDate, setExpireDate] = useState("");
  const navigate = useNavigate();

  //Validation Email
  var mailformat =
    /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  // /\S+@\S+\.\S+/
  function isValidEmail(email) {
    return mailformat.test(email);
  }

  function validateForm() {
    setLoading(true);
    // Perform operation and then set loading to false
    // setTimeout(() => {
    //   setLoading(false);
    // }, 500);
// console.log(value);

if(value === undefined || value === null){
  setErrormsg('Veuillez entrer un numéro WhatsApp correct');
   setLoading(false);
    setTimeout(() => {
      setErrormsg("");
    }, 2000);
  return null;
}

    if (
      pseudo === "" ||
      email === "" ||
      // value === "" ||
      // value === "undefined" ||
      password === "" ||
      passwordConfirme === ""
    ) {
      setLoading(false);
      setErrormsg("Veuillez remplir tous les champs et un numéro correct ! *");
      setTimeout(() => {
        setErrormsg("");
      }, 2000);
    }

    if (password.length < 8 || password.length > 12) {
      setLoading(false);
      setErrormsg(
        "Attention, votre mot de passe doit être compris entre 8 et 12 caractères y compris des chiffres et des symboles !"
      );
      setTimeout(() => {
        setErrormsg("");
      }, 2000);
    }

    // console.log(formData.cgv);

    if (!isValidEmail(email)) {
      setLoading(false);
      setErrormsg("Attention, Votre adresse E-mail est invalide !");
      //console.log(isValidEmail(email));
      setTimeout(() => {
        setErrormsg("");
      }, 2000);
    }

    if (password !== passwordConfirme) {
      setLoading(false);
      setErrormsg("Les mots de passe ne sont pas identiques*");
      setTimeout(() => {
        setErrormsg("");
      }, 2000);
    }

    var Emailconfirm = isValidEmail(email);

    if (
      pseudo !== "" &&
      email !== "" &&
      // value !== "" &&
      value !==undefined &&
      password !== "" &&
      passwordConfirme !== "" && password.length>=8 &&
      Emailconfirm !== false
    ) {
      // setLoading(true);
      // console.log(pseudo, email, value, password, passwordConfirme)
      var ExpireDate = Date.now();

      //  const axios = require("axios");
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        pseudo: pseudo,
        email: email,
        phone: value,
        password: password,
        role: "membre",
        flixEmail: "********",
        flixPass: "********",
        profilName: "Go2412",
        profilPass: "********",
        notify: "0",
        ExpireDate: ExpireDate,
        forme: "forme",
        isActive: true,
      });
      // console.log(raw);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(process.env.React_App_base_url + "users", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          // console.log(result);
          let resdata = JSON.parse(result);
          const resp = resdata.statusCode;
          //console.log(resdata);
          // console.log(resp);
          if (resp === 200) {
            // console.log(sponsorkey);
            let userObject = "Inscription sur GoFLIX";
            let message =
              "Hello Mme/M." +
              " " +
              "<b>" +
              pseudo +
              "</b><br><br>" +
              "Nous sommes heureux de vous accueillir dans la grande famille " +
              "<b>GoFLIX !</b>" +
              "<br>" +
              " Votre compte a été créé avec succès 👌." +
              "<br>" +
              "Votre mot de passe est: " +
              "<b>" +
              password +
              "</b><br>" +
              " Nous partageons les coûts pour un plaisir de divertissement à volonté." +
              "<br>" +
              " Merci de vous connecter et de souscrire à votre abonnement." +
              "<br><br>" +
              "<a href='https://goflix.pro' target='_blank'>Se connecter &rarr;</a>" +
              "<br><br>" +
              "Equipe GoFLIX";

            InscriptionEmail(email, userObject, message);

            setTimeout(() => {
              setLoading(false);
              navigate("../login");
            }, "1000");
          }

          if (resp === 302) {
            setLoading(false);
            setErrormsg(
              "Attention 🧐, un compte est déjà enrégistré avec cette addresse E-mail ou le numéro whatsApp !"
            );
            setTimeout(() => {
              setErrormsg("");
            }, 2000);
          }
        })
        .catch((error) => error);
    }
  }

  return (
    <div className="bg-gray-900 text-gray-100">
      <div className="bg-gray-900">
        <Header />
      </div>
      <div className="flex min-h-full flex-1  ">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div className="mx-auto text-center mb-16">
              <p className="text-3xl font-bold mt-16 -mb-8"> S'inscrire</p>
              {/* <img
                className="h-10 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt="Your Company"
              /> */}
              <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Se connecter
              </h2>
              <p className="mt-2 text-sm leading-6 text-pink-500">
                Déjà membre ?{" "}
                <a
                  href="./login"
                  className="font-semibold text-gray-100 hover:text-pink-500"
                >
                  Se connecter &rarr;
                </a>
              </p>
            </div>
            <p class="text-center p-2 -mb-4 text-red-700">{errormsg} </p>
            <div className="mt-10">
              <div>
                <div className="space-y-6">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-gray-100"
                    >
                      Pseudo
                    </label>
                    <div className="mt-2">
                      <input
                        id="pseudo"
                        name={pseudo}
                        type="text"
                        autoComplete="pseudo"
                        onChange={(e) => setPseudo(e.target.value)}
                        required
                        className="block w-full p-2  rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 text-gray-900 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-100"
                    >
                      Addresse E-mail
                    </label>
                    <div className="mt-2">
                      <input
                        id="email"
                        name={email}
                        type="email"
                        autoComplete="email"
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="block w-full p-2 text-gray-900 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-100"
                    >
                      Numéro Whatsapp
                    </label>
                    <div className="mt-2 text-gray-500">
                      <PhoneInput
                        country="US"
                        className="block w-full p-2 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="Entrez votre numéro whatsApp"
                        value={value}
                        onChange={setValue}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-100"
                    >
                      Mot de Passe
                    </label>
                    <div className="mt-2">
                      <input
                        id="password"
                        name={password}
                        type="text"
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                        className="block w-full text-gray-900 rounded-md p-2 border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-100"
                    >
                      Confirmez le Mot de Passe
                    </label>
                    <div className="mt-2 ">
                      <input
                        id="passwordConfirme"
                        name={passwordConfirme}
                        onChange={(e) => setPasswordConfirme(e.target.value)}
                        type="text"
                        autoComplete="current-password"
                        required
                        className="block w-full text-gray-900 p-2 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  {/* <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-3 block text-sm leading-6 text-gray-100"
                      >
                        Restez connecté
                      </label>
                    </div>

                    <div className="text-sm leading-6">
                      <a
                        href="#"
                        className="font-semibold text-pink-500 hover:text-gray-100"
                      >
                        Mot de passe oubliez ?
                      </a>
                    </div>
                  </div> */}

                  <div>
                    <button
                      type="submit"
                      enable={loading}
                      onClick={() => {
                        validateForm();
                      }}
                      className="flex w-full justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-yellow-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      {" "}
                      {loading ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 animate-spin mx-auto"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                          />
                        </svg>
                      ) : (
                        "Valider"
                      )}
                    </button>
                  </div>
                </div>
              </div>

              {/* <div className="mt-10">
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-200" />
                  </div>
                  <div className="relative flex justify-center text-sm font-medium leading-6">
                    <span className="bg-white px-6 text-gray-900">
                      Or continue with
                    </span>
                  </div>
                </div>

                <div className="mt-6 grid grid-cols-2 gap-4">
                  <a
                    href="#"
                    className="flex w-full items-center justify-center gap-3 rounded-md bg-[#1D9BF0] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#1D9BF0]"
                  >
                    <svg
                      className="h-5 w-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                    </svg>
                    <span className="text-sm font-semibold leading-6">
                      Twitter
                    </span>
                  </a>

                  <a
                    href="#"
                    className="flex w-full items-center justify-center gap-3 rounded-md bg-[#24292F] px-3 py-1.5 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F]"
                  >
                    <svg
                      className="h-5 w-5"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                    <span className="text-sm font-semibold leading-6">
                      GitHub
                    </span>
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src=".././1finances.jpg"
            alt=""
          />
        </div>
      </div>
      <div class="text-center text-gray-100 bg-pink-500 font-medium ">
        &copy;2023 CopyRight. Tout droit réservé GoFLIX.
      </div>
    </div>
  );
}

export default Signup;
