import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import Loader from "./loader";
import "./Appdash.css";




function Profil() {
  const isAuthenticated = localStorage.getItem("uuid");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [msg1, setMsg1] = useState("");
  const [notifications, setNotifications] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("uuid") !== null
  );

  const navigate = useNavigate();
  // SPINNER
  setTimeout(() => {
    setIsLoading(false);
  }, 2000);
  // SPINNER

  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated) {
        let StateId = atob(localStorage.getItem("isLoggedIn"));
        // console.log(StateId, isAuthenticated);

        if (StateId) {
          axios
            .get(process.env.React_App_base_url + `users/` + StateId, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Content-Type": "application/json",
                Authorization: `Bearer ${isAuthenticated}`,
              },
            })
            .then((res) => {
              //  console.log(res.data.data);
              const userData = res.data.data;
              setUserName(userData.pseudo);
              setEmail(userData.email);
              setPhone(userData.phone);
              setNotifications(userData.notify);

              //  console.log(res.dada.data.pseudo)
              let ActivationCompte = res.data.data.isActive;

              if (ActivationCompte !== true) {
                navigate(".././login");
              }

              //console.log(res.data);
              // let getOpt = res.data.NewData.codeOtp;
              // console.log(getOpt);
            });
        }
      }
    }, 1000);
  }, []);

  //  User inactivity

  function Userinactivity() {
    var time;
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(handleLogout, 100000000);
    }
  }

  Userinactivity();

  //Check if user Login
  let TokenStatic = "HDHYI67!UY~@LJHF9H9W6L4";

  //Check if user Login

  if (!isAuthenticated) {
    navigate("../login", { replace: true });
  }

  if (isAuthenticated) {
    let CheckUserlogged = localStorage.getItem("reactjs");

    if (atob(CheckUserlogged) !== TokenStatic) {
      localStorage.clear();
      navigate("../login", { replace: true });
    }
  }

  function checkUserExist() {
    if (!loggedIn) {
      // if the user is not logged in, redirect to the login page
      window.location.href = "../login";
      return null;
    }
  }

  useEffect(() => {
    checkUserExist();
  }, []);
  //Check if user Login

  const handleLogout = () => {
    // clear the token from local storage and set the user as logged out
    // localStorage.removeItem("uuid");
    localStorage.clear();
    setLoggedIn(false);
    window.location.href = "../login";
    return null;
  };

  function UpdateInfo() {
    setLoading(true);
    // Perform operation and then set loading to false
    setTimeout(() => {
      setLoading(false);
    }, 500);
    if (password === "") {
      setMsg1("Veuillez saisir votre nouveau mot de passe !");
      setTimeout(() => {
        setMsg("");
        setMsg1("");
      }, "2000");
    }

    if (password !== "") {
      const data = JSON.stringify({
        // pseudo: userName,
        // email: email,
        // phone: phone,
        password: password,
      });
      let userID = atob(localStorage.getItem("isLoggedIn"));
      axios
        .patch(process.env.React_App_base_url + `users/` + userID, data, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Content-Type": "application/json",
            Authorization: `Bearer ${isAuthenticated}`,
          },
        })
        .then((res) => {
          // console.log(res);
          setMsg("Votre profil a été mise à jour avec succès 👌 ! ");
          setTimeout(() => {
            setPassword("");
            setMsg("");
          }, "3000");
        });
    }
  }
  return (
    <>
      {isLoading ? (
        <div className="mx-auto my-auto w-24">
          <Loader />
        </div>
      ) : (
        <div className="bg-gray-900 text-gray-100">
          <link
            href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp"
            rel="stylesheet"
          />

          <div class="bg-gray-900 min-h-screen">
            <div class="fixed bg-white text-blue-800 px-10 py-1 z-10 w-full">
              <div class="flex items-center justify-between py-2 text-5x1">
                <div class="font-bold text-blue-900 text-xl">
                  <span className="hover:text-gray-900 text-4xl font-bold text-pink-500">
                    {" "}
                    GoFLIX .
                  </span>
                  {/* <span class="text-gray-900 mx-auto text-center">Tableau de Bord</span> */}
                </div>
                <div class="flex items-center text-gray-500">
                  {/* <span class="material-icons-outlined p-2">search</span> */}
                  <a
                    href="./mycompte"
                    title="Voir les identifiants Netflix reçus"
                    class="material-icons-outlined p-2"
                  >
                    notifications{" "}
                  </a>
                  <div class="inline-flex items-center -ml-4 px-1.5 py-0.5 border-2 border-white rounded-full text-xs font-semibold leading-4 bg-red-500 text-white">
                    {notifications}
                  </div>
                  <img
                    className="bg-center bg-cover bg-no-repeat rounded-full border-2 inline-block h-12 w-12 ml-2"
                    src=".././enfant _digital.jpeg"
                    alt=""
                  />
                  <span class="">{userName}</span>
                </div>
              </div>
            </div>

            <div class="flex flex-row pt-24  px-10 pb-4">
              <div class="myCompte w-2/12 mr-6">
                <div class="bg-white rounded-xl shadow-lg mb-6 px-6 py-4">
                  <a
                    href="./homepage"
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      dashboard
                    </span>
                    Home
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </a>
                  <a
                    href="./mycompte"
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      tune
                    </span>
                    Mon compte
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </a>
                  <a
                    href="./historiques"
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      file_copy
                    </span>
                    Historiques
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </a>
                </div>

                <div class="bg-white rounded-xl shadow-lg mb-6 px-6 py-4">
                  <a
                    href="./profil"
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      face
                    </span>
                    Profil
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </a>
                  {/* <a
                href=""
                class="inline-block text-gray-600 hover:text-black my-4 w-full"
              >
                <span class="material-icons-outlined float-left pr-2">
                  settings
                </span>
                Settings
                <span class="material-icons-outlined float-right">
                  keyboard_arrow_right
                </span>
              </a> */}
                  <button
                    onClick={handleLogout}
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      power_settings_new
                    </span>
                    Déconnexion
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </button>
                </div>
              </div>

              <link
                href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp"
                rel="stylesheet"
              />

              <div class="myProfil bg-gray-900 min-h-screen">
                <body
                  class="font-sans antialiased text-gray-900 leading-normal tracking-wider bg-cover
    bg-url('https://source.unsplash.com/1L71sPT5XKc');"
                >
                  <div class="max-w-4xl flex items-center h-auto lg:h-screen flex-wrap mx-auto my-32 lg:my-0">
                    <div
                      id="profile"
                      class="w-full lg:w-3/5 rounded-lg lg:rounded-l-lg lg:rounded-r-none shadow-2xl bg-white opacity-75 mx-6 lg:mx-0"
                    >
                      <div class="p-4 md:p-12 text-center lg:text-left">
                        <div
                          class="block lg:hidden rounded-full shadow-xl mx-auto -mt-16 h-48 w-48 bg-cover bg-center
                    bg-url('https://source.unsplash.com/MP0IUfwrn0A')"
                        ></div>

                        <h1 class="text-3xl font-bold pt-8 lg:pt-0">
                          {userName}
                        </h1>
                        <div class="mx-auto lg:mx-0 w-4/5 pt-3 border-b-2 border-green-500 opacity-25"></div>
                        <p class="pt-4 text-base font-bold flex items-center justify-center lg:justify-start">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-4 fill-current text-green-700 pr-4"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fill="currentColor"
                              d="M18 7.373V14.5a2.5 2.5 0 0 1-2.5 2.5h-11A2.5 2.5 0 0 1 2 14.5V7.373l7.746 4.558a.5.5 0 0 0 .508 0zM15.5 4a2.5 2.5 0 0 1 2.485 2.223L10 10.92L2.015 6.223A2.5 2.5 0 0 1 4.5 4z"
                            />
                          </svg>{" "}
                          {email}
                        </p>
                        <p class="pt-4 text-base font-bold flex items-center justify-center lg:justify-start">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-4 fill-current text-green-700 pr-4"
                            viewBox="0 0 24 24"
                          >
                            <g fill="none">
                              <g clip-path="url(#akarIconsWhatsappFill0)">
                                <path
                                  fill="currentColor"
                                  fill-rule="evenodd"
                                  d="M17.415 14.382c-.298-.149-1.759-.867-2.031-.967c-.272-.099-.47-.148-.669.15c-.198.297-.767.966-.94 1.164c-.174.199-.347.223-.644.075c-.297-.15-1.255-.463-2.39-1.475c-.883-.788-1.48-1.761-1.653-2.059c-.173-.297-.019-.458.13-.606c.134-.133.297-.347.446-.52c.149-.174.198-.298.297-.497c.1-.198.05-.371-.025-.52c-.074-.149-.668-1.612-.916-2.207c-.241-.579-.486-.5-.668-.51c-.174-.008-.372-.01-.57-.01c-.198 0-.52.074-.792.372c-.273.297-1.04 1.016-1.04 2.479c0 1.462 1.064 2.875 1.213 3.074c.149.198 2.095 3.2 5.076 4.487c.71.306 1.263.489 1.694.625c.712.227 1.36.195 1.872.118c.57-.085 1.758-.719 2.006-1.413c.247-.694.247-1.289.173-1.413c-.074-.124-.272-.198-.57-.347m-5.422 7.403h-.004a9.87 9.87 0 0 1-5.032-1.378l-.36-.214l-3.742.982l.999-3.648l-.235-.374a9.861 9.861 0 0 1-1.511-5.26c.002-5.45 4.436-9.884 9.889-9.884a9.81 9.81 0 0 1 6.988 2.899a9.825 9.825 0 0 1 2.892 6.992c-.002 5.45-4.436 9.885-9.884 9.885m8.412-18.297A11.815 11.815 0 0 0 11.992 0C5.438 0 .102 5.335.1 11.892a11.864 11.864 0 0 0 1.587 5.945L0 24l6.304-1.654a11.881 11.881 0 0 0 5.684 1.448h.005c6.554 0 11.89-5.335 11.892-11.893a11.821 11.821 0 0 0-3.48-8.413"
                                  clip-rule="evenodd"
                                />
                              </g>
                              <defs>
                                <clipPath id="akarIconsWhatsappFill0">
                                  <path fill="#fff" d="M0 0h24v24H0z" />
                                </clipPath>
                              </defs>
                            </g>
                          </svg>{" "}
                          {phone}
                        </p>
                        <p class="pt-2 text-gray-600 text-xs lg:text-sm flex items-center justify-center lg:justify-start">
                          <svg
                            class="h-4 fill-current text-green-700 pr-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm7.75-8a8.01 8.01 0 0 0 0-4h-3.82a28.81 28.81 0 0 1 0 4h3.82zm-.82 2h-3.22a14.44 14.44 0 0 1-.95 3.51A8.03 8.03 0 0 0 16.93 14zm-8.85-2h3.84a24.61 24.61 0 0 0 0-4H8.08a24.61 24.61 0 0 0 0 4zm.25 2c.41 2.4 1.13 4 1.67 4s1.26-1.6 1.67-4H8.33zm-6.08-2h3.82a28.81 28.81 0 0 1 0-4H2.25a8.01 8.01 0 0 0 0 4zm.82 2a8.03 8.03 0 0 0 4.17 3.51c-.42-.96-.74-2.16-.95-3.51H3.07zm13.86-8a8.03 8.03 0 0 0-4.17-3.51c.42.96.74 2.16.95 3.51h3.22zm-8.6 0h3.34c-.41-2.4-1.13-4-1.67-4S8.74 3.6 8.33 6zM3.07 6h3.22c.2-1.35.53-2.55.95-3.51A8.03 8.03 0 0 0 3.07 6z" />
                          </svg>{" "}
                          Famille GoFLIX
                        </p>
                        <p class="pt-8 text-sm">
                          Changez votre mote de passe ?
                        </p>
                        <div class="bg-white p-4 rounded-lg ">
                          <div class="relative bg-inherit">
                            <input
                              type="text"
                              id="password"
                              name={password}
                              onChange={(e) => setPassword(e.target.value)}
                              class=" bg-transparent h-10 w-72 rounded-lg text-gray-900 placeholder-transparent ring-2 px-2 ring-gray-500 focus:ring-sky-600 focus:outline-none focus:border-rose-600"
                              placeholder="Type inside me"
                            />
                            <label
                              for="username"
                              class="absolute cursor-text left-0 -top-3 text-sm text-gray-500 bg-inherit mx-1 px-1 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3 peer-focus:text-sky-600 peer-focus:text-sm transition-all"
                            >
                              Tapez le nouveau mot de passe
                            </label>
                          </div>
                        </div>
                        <div className="text-red-700 p-2">{msg1}</div>
                        <div className="text-green-700 p-2">{msg}</div>
                        <div class="pt-12 pb-8">
                          <button
                            enable={loading}
                            onClick={() => {
                              UpdateInfo();
                            }}
                            class="bg-green-700 hover:bg-green-900 text-white font-bold py-2 px-4 rounded-full"
                          >
                            {loading ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-6 h-6 animate-spin mx-auto"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                                />
                              </svg>
                            ) : (
                              "Mettre à jour →"
                            )}
                          </button>
                        </div>

                        <div class="mt-6 pb-16 lg:pb-0 w-4/5 lg:w-full mx-auto flex flex-wrap items-center justify-between">
                          <a
                            class="link"
                            href="#"
                            data-tippy-content="@facebook_handle"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-6 fill-current text-gray-600 hover:text-green-700"
                              viewBox="0 0 512 138"
                            >
                              <path
                                fill="#DB202C"
                                d="M340.657 0v100.203c12.36.575 24.687 1.27 36.98 2.09v21.245a1822.444 1822.444 0 0 0-58.542-2.959V0zM512 .012l-28.077 65.094l28.07 72.438l-.031.013a1789.409 1789.409 0 0 0-24.576-3.323l-15.763-40.656l-15.913 36.882a1815.88 1815.88 0 0 0-22.662-2.36l27.371-63.43L435.352.013h23.325l14.035 36.184L488.318.012zM245.093 119.526V.011h60.19v21.436h-38.628v27.78h29.227v21.245h-29.227v49.05zM164.58 21.448V.01h66.69v21.437h-22.565v98.66c-7.197.19-14.386.412-21.56.683V21.448zM90.868 126.966V.014h59.89v21.435h-38.331v29.036c8.806-.113 21.327-.24 29.117-.222V71.51c-9.751-.12-20.758.134-29.117.217v32.164a1848.195 1848.195 0 0 1 38.331-2.62v21.247a1815.638 1815.638 0 0 0-59.89 4.45M48.571 77.854L48.57.01h21.562v128.96c-7.882.81-15.75 1.673-23.603 2.584L21.56 59.824v74.802a1834.87 1834.87 0 0 0-21.561 2.936V.012H20.49zm346.854 46.965V.012h21.563V126.6c-7.179-.64-14.364-1.23-21.563-1.78"
                              />
                            </svg>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="w-full lg:w-2/5">
                      <img
                        src=".././Cata.jpg"
                        class="rounded-none lg:rounded-lg shadow-2xl hidden lg:block"
                      />
                    </div>

                    <div class="absolute top-0 right-0 h-12 w-18 p-4">
                      <button class="js-change-theme focus:outline-none">
                        🌙
                      </button>
                    </div>
                  </div>
                </body>
              </div>
            </div>
          </div>
          <div class="text-center text-gray-100 bg-pink-500 font-medium ">
            &copy;2023 CopyRight. Tout droit réservé GoFLIX.
          </div>
        </div>
      )}
    </>
  );
}

export default Profil;
