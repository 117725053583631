import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Accueil from "./Accueil";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Login from './Login';
import Signup from './Singup';
import Homepage from './Dash/Homepage';
import Profil from './Dash/Profil';
import Mycompte from './Dash/Mycompte';
import Historiques from './Dash/Historiques';
import Administrator from './Dash/Administrator';
import Commande from './Dash/Commande';
import Updatecompte from './Dash/Updatecompte';
import PasswordRecovery from './PasswordRecovery';
import Endpoint from './Dash/Endpoint';
import Updatecommande from './Dash/Updatecommande';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <div>
        <section>
          <Routes>
            {" "}
            <Route path="/" element={<Accueil />} />
            <Route path="/Login" element={<Login />} />
            <Route path="/Signup" element={<Signup />} />
            <Route path="/Dash/Homepage" element={<Homepage />} />{" "}
            <Route path="/Dash/Profil" element={<Profil />} />{" "}
            <Route path="/Dash/Mycompte" element={<Mycompte />} />{" "}
            <Route path="/Dash/Historiques" element={<Historiques />} />{" "}
            <Route path="/Dash/Administrator" element={<Administrator />} />{" "}
            <Route path="/Dash/Commande" element={<Commande />} />{" "}
            <Route path="/Dash/Updatecompte" element={<Updatecompte />} />{" "}
            <Route path="/Dash/Updatecommande" element={<Updatecommande />} />{" "}
            <Route path="/Dash/Endpoint" element={<Endpoint />} />{" "}
            <Route path="/PasswordRecovery" element={<PasswordRecovery />} />{" "}
          </Routes>
        </section>
      </div>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
