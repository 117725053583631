import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import Loader from "./loader";
import sendEmail from ".././SendEmail";
import WasenderClient from "./WasenderClient";


function Updatecommande() {
  const isAuthenticated = localStorage.getItem("uuid");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [userNameP, setUserNameP] = useState("");
  const [userNameP2, setUserNameP2] = useState("");

  const [emailP, setEmailP] = useState("");
  const [phoneP, setPhoneP] = useState("");
  const [femail, setFemail] = useState("");
  const [fpass, setFpass] = useState("");
  const [pname, setPname] = useState("");
  const [ppass, setPpass] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [msg, setMsg] = useState("");
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("uuid") !== null
  );

  const navigate = useNavigate();

  let Commande_Id = localStorage.getItem("isReact");

  let userCommande_Id = localStorage.getItem("isCo");

  if (Commande_Id === null) {
    navigate("../dash/administrator");
  }

  // SPINNER
  setTimeout(() => {
    setIsLoading(false);
  }, 2000);
  // SPINNER

  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated) {
        let StateId = atob(localStorage.getItem("isLoggedIn"));
        // console.log(StateId, isAuthenticated);

        if (StateId) {
          axios
            .get(process.env.React_App_base_url + `users/` + StateId, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Content-Type": "application/json",
                Authorization: `Bearer ${isAuthenticated}`,
              },
            })
            .then((res) => {
              //  console.log(res.data.data);
              const userData = res.data.data;
              setUserName(userData.pseudo);
              // setEmail(userData.email);
              // setPhone(userData.phone);

              //  console.log(res.dada.data.pseudo)
              let ActivationCompte = res.data.data.isActive;

              if (ActivationCompte !== true) {
                navigate(".././login");
              }

              //console.log(res.data);
              // let getOpt = res.data.NewData.codeOtp;
              // console.log(getOpt);
            });
        }
      } else {
        navigate(".././login");
      }
    }, 1000);
  }, []);

  useEffect(() => {
    if (Commande_Id) {
      setTimeout(() => {
        const isCo_Id = atob(userCommande_Id);
        axios
          .get(process.env.React_App_base_url + `abonnements/` + isCo_Id, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Content-Type": "application/json",
              Authorization: `Bearer ${isAuthenticated}`,
            },
          })
          .then((res) => {
            //  console.log(res.data.data);
            const userData = res.data.data;
            // console.log(res);
            setEmail(userData.userEmail);
            setPhone(userData.userPhone);
            setUserNameP2(userData.pseudo);
            setEmailP(userData.email);
            setPhoneP(userData.phone);
            setFemail(userData.flixEmail);
            setFpass(userData.flixPass);
            setPname(userData.profilName);
            setPpass(userData.profilPass);
          });
      }, 3000);
    }
  }, []);

  const handleLogout = () => {
    // clear the token from local storage and set the user as logged out
    // localStorage.removeItem("uuid");
    localStorage.clear();
    setLoggedIn(false);
    window.location.href = "../login";
    return null;
  };

  function ChargerCompte() {
    // console.log(femail, fpass, pname,ppass);
    setLoading(true);
    // Perform operation and then set loading to false
    setTimeout(() => {
      setLoading(false);
    }, 500);
//  console.log(femail, fpass, pname, ppass);
    if (femail !== "" && fpass !== "" && pname !== "" && ppass !== "") {
      // console.log(femail, fpass, pname, ppass);
      const data = JSON.stringify({
        flixEmail: femail,
        flixPass: fpass,
        profilName: pname,
        profilPass: ppass,
      });
      // console.log(data);
      // let userID = atob(localStorage.getItem("isLoggedIn"));
      const isCo_Id = atob(userCommande_Id);
      axios.patch(
          process.env.React_App_base_url + `abonnements/` + isCo_Id,
          data,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Content-Type": "application/json",
              "Authorization": `Bearer ${isAuthenticated}`,
            },
          }
        )
        .then((res) => {
          //  console.log(res);
          
            let object="Vos identifiants de connexion NETFLIX"
            let message =
              "<b>Hello Mme/M. " +
              userNameP2 +
              "</b><br>" +
              "Url: https://www.netflix.com/" +
              "<br>" +
              "Login:Email: " +
              " <b>" +
              femail +
              "</b><br>" +
              "Mot de passe: " +
              " <b>" +
              fpass +
              "</b><br>" +
              " Nom de profil :" +
              "<b>" +
              pname +
              " </b><br>" +
              " Mot de passe :" +
              "<b>" +
              ppass +
              "</b><br><br>" +
              "N'oubliez pas de respecter les règles d'utilisation de votre profil. Pas de connexion à plusieurs appareils au même moment. Veuillez vous déconnecter à chaque fois avant de vous connecter à nouveau appareil." +
              "<br><br>" +
              " Merci pour la confiance 🙏 ! " +
              "<br><br>" +
              "Equipe GoFlix";
              // console.log(email, object, message)
            sendEmail(email,object, message );

            WasenderClient(phone, userNameP2, femail, fpass, pname, ppass);

            setMsg(
              "Les informations de l'abonnement ont été mise à jour avec succès 👌 ! "
            );
          setTimeout(() => {
            setMsg("");
          }, "5000");
        });
    }
  }

  return (
    <>
      {isLoading ? (
        <div className="mx-auto my-auto w-24">
          <Loader />
        </div>
      ) : (
        <div className="bg-gray-900 text-gray-100">
          <link
            href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp"
            rel="stylesheet"
          />

          <div class="bg-gray-900 min-h-screen">
            <div class="fixed bg-white text-blue-800 px-10 py-1 z-10 w-full">
              <div class="flex items-center justify-between py-2 text-5x1">
                <div class="font-bold text-blue-900 text-xl">
                  <span className="hover:text-gray-900 text-4xl font-bold text-pink-500">
                    {" "}
                    GoFLIX .
                  </span>
                  {/* <span class="text-gray-900 mx-auto text-center">Tableau de Bord</span> */}
                </div>
                <div class="flex items-center text-gray-500">
                  {/* <span class="material-icons-outlined p-2">search</span> */}
                  {/* <span class="material-icons-outlined p-2">notifications</span> */}
                  <img
                    className="bg-center bg-cover bg-no-repeat rounded-full border-2 inline-block h-12 w-12 ml-2"
                    src=".././enfant _digital.jpeg"
                    alt=""
                  />
                  <span class="">{userName}</span>
                </div>
              </div>
            </div>

            <div class="flex flex-row pt-24  px-10 pb-4">
              <div class="w-2/12 mr-6">
                <div class="bg-white rounded-xl shadow-lg mb-6 px-6 py-4">
                  <a
                    href="./administrator"
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      dashboard
                    </span>
                    Home
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </a>
                  {/* <a
                href="./mycompte"
                class="inline-block text-gray-600 hover:text-black my-4 w-full"
              >
                <span class="material-icons-outlined float-left pr-2">
                  tune
                </span>
                Mon compte
                <span class="material-icons-outlined float-right">
                  keyboard_arrow_right
                </span>
              </a> */}
                  <a
                    href="./commande"
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      file_copy
                    </span>
                    Commande
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </a>
                </div>

                <div class="bg-white rounded-xl shadow-lg mb-6 px-6 py-4">
                  {/* <a
                href="./profil"
                class="inline-block text-gray-600 hover:text-black my-4 w-full"
              >
                <span class="material-icons-outlined float-left pr-2">
                  face
                </span>
                Profil
                <span class="material-icons-outlined float-right">
                  keyboard_arrow_right
                </span>
              </a> */}
                  {/* <a
                href=""
                class="inline-block text-gray-600 hover:text-black my-4 w-full"
              >
                <span class="material-icons-outlined float-left pr-2">
                  settings
                </span>
                Settings
                <span class="material-icons-outlined float-right">
                  keyboard_arrow_right
                </span>
              </a> */}
                  <button
                    onClick={handleLogout}
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      power_settings_new
                    </span>
                    Déconnexion
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </button>
                </div>
              </div>

              <div class="bg-white overflow-hidden w-full shadow rounded-lg border">
                <div class="px-4 py-5 sm:px-6">
                  <h3 class="text-lg leading-6 font-medium text-gray-900">
                    CHARGER LE COMPTE | MISE A JOUR DE NETFLIX
                  </h3>
                  <p class="mt-1 max-w-2xl text-sm text-gray-500">
                    Il s'agit d'informations sur l'utilisateur.
                  </p>
                </div>
                <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                  <dl class="sm:divide-y sm:divide-gray-200">
                    <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">Pseudo</dt>
                      <dd class="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2">
                        {userNameP2}
                      </dd>
                    </div>
                    <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Email address
                      </dt>
                      <dd class="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2">
                        {email}
                      </dd>
                    </div>
                    <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium font-bold text-gray-500">
                        Phone number
                      </dt>
                      <dd class="mt-1 text-sm text-gray-900 font-bold sm:mt-0 sm:col-span-2">
                        {phone}
                      </dd>
                    </div>
                    <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        Identifiant NETFLIX
                      </dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {/* E-mail:{" "} */}
                        <div class="relative bg-inherit">
                          <input
                            type="text"
                            name="femail"
                            onChange={(e) => setFemail(e.target.value)}
                            value={femail}
                            class=" bg-transparent h-10 w-72 rounded-lg text-gray-900 placeholder-transparent ring-2 px-2 ring-gray-500 focus:ring-sky-600 focus:outline-none focus:border-rose-600"
                            placeholder="Email"
                          />
                          <label
                            for="username"
                            class="absolute cursor-text left-0 -top-5 text-sm text-gray-500 bg-inherit mx-1 px-1 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3 peer-focus:text-sky-600 peer-focus:text-sm transition-all"
                          >
                            E-mail compte Netflix
                          </label>
                        </div>
                        <br />
                        <br />
                        <div class="relative bg-inherit">
                          <input
                            type="text"
                            // name={ppassword}
                            onChange={(e) => setFpass(e.target.value)}
                            value={fpass}
                            class=" bg-transparent h-10 w-72 rounded-lg text-gray-900 placeholder-transparent ring-2 px-2 ring-gray-500 focus:ring-sky-600 focus:outline-none focus:border-rose-600"
                            placeholder="Type inside me"
                          />
                          <label
                            for="username"
                            class="absolute cursor-text left-0 -top-5 text-sm text-gray-500 bg-inherit mx-1 px-1 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3 peer-focus:text-sky-600 peer-focus:text-sm transition-all"
                          >
                            Mot de passe compte Netflix
                          </label>
                        </div>
                      </dd>
                    </div>
                    <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt class="text-sm font-medium text-gray-500">
                        ACCES PROFIL
                      </dt>
                      <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        <div class="relative bg-inherit">
                          <input
                            type="text"
                            // name={password}
                            onChange={(e) => setPname(e.target.value)}
                            value={pname}
                            class=" bg-transparent h-10 w-72 rounded-lg text-gray-900 placeholder-transparent ring-2 px-2 ring-gray-500 focus:ring-sky-600 focus:outline-none focus:border-rose-600"
                            placeholder=""
                          />
                          <label
                            for="username"
                            class="absolute cursor-text left-0 -top-5 text-sm text-gray-500 bg-inherit mx-1 px-1 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3 peer-focus:text-sky-600 peer-focus:text-sm transition-all"
                          >
                            Nom profil Netflix
                          </label>
                        </div>
                        <br />
                        <br />
                        <div class="relative bg-inherit">
                          <input
                            type="text"
                            onChange={(e) => setPpass(e.target.value)}
                            value={ppass}
                            class=" bg-transparent h-10 w-72 rounded-lg text-gray-900 placeholder-transparent ring-2 px-2 ring-gray-500 focus:ring-sky-600 focus:outline-none focus:border-rose-600"
                            placeholder=""
                          />
                          <label
                            for="username"
                            class="absolute cursor-text left-0 -top-5 text-sm text-gray-500 bg-inherit mx-1 px-1 peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-500 peer-placeholder-shown:top-2 peer-focus:-top-3 peer-focus:text-sky-600 peer-focus:text-sm transition-all"
                          >
                            Mot de passe profil Netflix
                          </label>
                        </div>
                      </dd>
                    </div>
                  </dl>
                  <div className="text-green-700 p-2 text-2xl text-center">
                    {msg}
                  </div>
                  <dl className="m-4">
                    <button
                      enable={loading}
                      onClick={ChargerCompte}
                      class="text-pink-500 border border-pink-500 hover:bg-pink-500 hover:text-white py-4 px-6 rounded inline-flex items-center"
                    >
                      {loading ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 animate-spin mx-auto"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                          />
                        </svg>
                      ) : (
                        "Enregistrer →"
                      )}
                    </button>
                  </dl>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center text-gray-100 bg-pink-500 font-medium ">
            &copy;2023 CopyRight. Tout droit réservé GoFLIX.
          </div>
        </div>
      )}
    </>
  );
}

export default Updatecommande;
