import axios from "axios";

const Token = process.env.React_App_wa_token;
const templateId = process.env.React_App_wa_templ_id_2;
const url = process.env.React_App_wa_url;
// let  details="";

const Wasender = async (number,details) => {
  try {
    let data = JSON.stringify({
      messaging_product: "whatsapp",
      recipient_type: "individual",
      to: number,
      type: "template",
      template: {
        name: templateId,
        language: {
          code: "fr",
        },
        components: [
          {
            type: "header",
          },
          {
            type: "body",
            parameters: [
              {
                type: "text",
                text: details,
              },
            ],
          },
          // {
          //   type: "button",
          //   sub_type: "url",
          //   index: "0",
          //   parameters: [
          //     {
          //       type: "TEXT",
          //       text: "Copiez",
          //     },
          //   ],
          // },
        ],
      },
    });

    var config = {
      method: "post",
      url: url,
      headers: {
        Authorization: `Bearer ${Token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config).then(function (response) {});
  } catch (error) {
    // console.error("Failed to send email. Error: ", error);
  }
};

export default Wasender;
