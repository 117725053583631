
import './App.css';
import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

function Intros() {
 
  return (
    <div className="mt-32 overflow-hidden sm:mt-40">
      <div className="mx-auto max-w-7xl px-6 lg:flex lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-12 gap-y-16 lg:mx-0 lg:min-w-full lg:max-w-none lg:flex-none lg:gap-y-8">
          <div className="lg:col-end-1 lg:w-full lg:max-w-lg lg:pb-8">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              Profitez de votre TV
            </h2>
            <p className="mt-6 text-xl leading-8 text-pink-500 text-justify">
              Regardez sur les Smart TV, Playstation, Xbox, Chromecast, Apple
              TV, lecteurs Blu-ray, et plus encore.
            </p>
            <p className="mt-6 text-base leading-7 text-gray-100 text-justify">
              Sauvegardez facilement vos favoris et ayez toujours quelque chose
              à regarder. Vous êtes informé des nouveautés, Films, Series et
              Emissions TV...Vous avez tout pour vous tenir compagnie à la
              maison, au bureau, dans vos sorties et au cours de vos voyages.
            </p>
            <p className="mt-6 text-gray-400 mb-8">
              <a
                href="./signup"
                className="rounded-md bg-pink-500 px-3.5  py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
              >
                Créer un compte maintenant &rarr;
              </a>
            </p>
          </div>
          <div className="flex flex-wrap items-start justify-end gap-6 sm:gap-8 lg:contents">
            <div className="w-0 flex-auto lg:ml-auto lg:w-auto lg:flex-none lg:self-end">
              <img
                src=".././streaming.gif"
                alt=""
                className="aspect-[7/5] w-[37rem]  transition duration-300 ease-in-out hover:scale-110  max-w-none rounded-2xl bg-gray-50 object-cover"
              />
            </div>
            <div className="contents lg:col-span-2 lg:col-end-2 lg:ml-auto lg:flex lg:w-[37rem] lg:items-start lg:justify-end lg:gap-x-8">
              <div className="order-first flex w-64 flex-none justify-end self-end lg:w-auto">
                <img
                  src=".././famille.png"
                  alt=""
                  className="aspect-[4/3] w-[24rem]  transition duration-300 ease-in-out hover:scale-110 max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
              <div className="flex w-96 flex-auto justify-end lg:w-auto lg:flex-none">
                <img
                  src=".././.././enfant _digital.jpeg"
                  alt=""
                  className="aspect-[7/5] w-[37rem]  transition duration-300 ease-in-out hover:scale-110 max-w-none flex-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
              <div className="hidden sm:block sm:w-0 sm:flex-auto lg:w-auto lg:flex-none">
                <img
                  src=".././amis.png"
                  alt=""
                  className="aspect-[4/3] w-[24rem]  transition duration-300 ease-in-out hover:scale-110 max-w-none rounded-2xl bg-gray-50 object-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Intros;
