import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import "jquery/dist/jquery.min.js";
import Loader from "./loader";
import "./Appdash.css";



//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

function Historiques() {
  const isAuthenticated = localStorage.getItem("uuid");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [msg1, setMsg1] = useState("");
  const [abonnementsData, setAbonnementsData] = useState([]);
  const [notifications, setNotifications] = useState("");
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("uuid") !== null
  );
  const [showModal, setShowModal] = useState(false);
  const [compteId, setCompteId] = useState("");
  const [flixE, setFlixE] = useState("");
  const [flixP, setFlixP] = useState("");
  const [profilN, setProfilN] = useState("");
  const [profilP, setProfilP] = useState("");
  const [flixExp, setFlixExp] = useState("");
  const [formy, setFormy] = useState("");
  const [dure, setDure] = useState("");
  const [isLoading, setIsLoading] = useState(true);


  const navigate = useNavigate();

  // SPINNER
  setTimeout(() => {
    setIsLoading(false);
  }, 5000);
  // SPINNER

if (compteId==="") {
  setTimeout(() => {
    axios
      .get(process.env.React_App_base_url + `abonnements`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": true,
          "Content-Type": "application/json",
          Authorization: `Bearer ${isAuthenticated}`,
        },
      })
      .then((res) => {
        // console.log(res);
        setShowModal(false);
        const userData = res.data.payment;
        const filteredAbonnements = userData.filter(
          (userData) =>
            userData["userEmail"] === email &&
            userData["paymentStatus"] === "confirmed"
        );
        // console.log(filteredAbonnements);

        setAbonnementsData(filteredAbonnements);

        setAbonnementsData(filteredAbonnements);
      });
  },3000,[]);
}    
          
      


  useEffect(() => {
     let StateId = atob(localStorage.getItem("isLoggedIn"));
        // console.log(StateId, isAuthenticated);
        if (StateId) {
          axios
            .get(process.env.React_App_base_url + `users/` + StateId, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Content-Type": "application/json",
                Authorization: `Bearer ${isAuthenticated}`,
              },
            })
            .then((res) => {
              //  console.log(res.data.data);
              const userData = res.data.data;
              setUserName(userData.pseudo);
              setEmail(userData.email);
              setPhone(userData.phone);
              setNotifications(userData.notify);

              //  console.log(res.dada.data.pseudo)
              let ActivationCompte = res.data.data.isActive;

              if (ActivationCompte !== true) {
                navigate(".././login");
              }

              //console.log(res.data);
              // let getOpt = res.data.NewData.codeOtp;
              // console.log(getOpt);
            });
        }     

  },[]);

  //  console.log(abonnementsData);

  //  User inactivity

  function Userinactivity() {
    var time;
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(handleLogout, 100000000);
    }
  }

  Userinactivity();

  //Check if user Login
  let TokenStatic = "HDHYI67!UY~@LJHF9H9W6L4";

  //Check if user Login

  if (!isAuthenticated) {
    navigate("../login", { replace: true });
  }

  if (isAuthenticated) {
    let CheckUserlogged = localStorage.getItem("reactjs");

    if (atob(CheckUserlogged) !== TokenStatic) {
      localStorage.clear();
      navigate("../login", { replace: true });
    }
  }

  function checkUserExist() {
    if (!loggedIn) {
      // if the user is not logged in, redirect to the login page
      window.location.href = "../login";
      return null;
    }
  }

  useEffect(() => {
    checkUserExist();
  }, []);
  //Check if user Login
  const handleLogout = () => {
    // clear the token from local storage and set the user as logged out
    // localStorage.removeItem("uuid");
    localStorage.clear();
    setLoggedIn(false);
    window.location.href = "../login";
    return null;
  };

  function close() {
    setTimeout(() => {
      setCompteId("");
      setShowModal(false);
    }, 2000);
  }

  if (compteId !== "") {    
    //  console.log(compteId);  
    // setIsLoading(true);
    setTimeout(() => {      //  setCompteId(compteId);
      axios
        .get(process.env.React_App_base_url + `abonnements/` + compteId, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Content-Type": "application/json",
            Authorization: `Bearer ${isAuthenticated}`,
          },
        })
        .then((res) => {
          let CompteDAta = res.data.data;
          setFlixE(CompteDAta.flixEmail);
          setFlixP(CompteDAta.flixPass);
          setProfilN(CompteDAta.profilName);
          setProfilP(CompteDAta.profilPass);
          setFormy(CompteDAta.forme);
          setFlixExp(
            new Date(parseInt(CompteDAta.ExpireDate)).toLocaleString("fr-FR", {
              timeZoneName: "short",
            })
          );          
           setDure(CompteDAta.abDure);  
          if(CompteDAta){          
                  // setIsLoading(false);
                   setShowModal(true); 
          }
          // console.log(CompteDAta);
             
        });
    },3000);
  }
  //JSdataTable
  $(document).ready(function () {
    setTimeout(function () {
      $("#example").DataTable({
        paging: true,
        searching: true,
        retrieve: true,
        language: {
          processing: "Traitement en cours...",
          search: "Recherche&nbsp;:",
          lengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
          info: "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
          infoEmpty:
            "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
          infoFiltered:
            "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
          infoPostFix: "",
          loadingRecords: "Chargement en cours...",
          zeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher",
          emptyTable: "Aucune donnée disponible dans le tableau",
          paginate: {
            first: "Premier",
            previous: "Pr&eacute;c&eacute;dent",
            next: "Suivant",
            last: "Dernier",
          },
        },
      });
    }, 5000);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="mx-auto my-auto w-24">
          <Loader />
        </div>
      ) : (
        <div className="bg-gray-900 text-gray-100">
          {/* <link
        href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp"
        rel="stylesheet"
      /> */}

          <div class="bg-gray-900 min-h-screen">
            <div class="fixed bg-white text-blue-800 px-10 py-1 z-10 w-full">
              <div class="flex items-center justify-between py-2 text-5x1">
                <div class="font-bold text-blue-900 text-xl">
                  <span className="hover:text-gray-900 text-4xl font-bold text-pink-500">
                    {" "}
                    GoFLIX .
                  </span>
                  {/* <span class="text-gray-900 mx-auto text-center">Tableau de Bord</span> */}
                </div>
                <div class="flex items-center text-gray-500">
                  {/* <span class="material-icons-outlined p-2">search</span> */}
                  <a
                    href="./mycompte"
                    title="Voir les identifiants Netflix reçus"
                    class="material-icons-outlined p-2"
                  >
                    notifications{" "}
                  </a>
                  <div class="inline-flex items-center -ml-4 px-1.5 py-0.5 border-2 border-white rounded-full text-xs font-semibold leading-4 bg-red-500 text-white">
                    {notifications}
                  </div>
                  <img
                    className="bg-center bg-cover bg-no-repeat rounded-full border-2 inline-block h-12 w-12 ml-2"
                    src=".././enfant _digital.jpeg"
                    alt=""
                  />
                  <span class="">{userName}</span>
                </div>
              </div>
            </div>

            <div class="flex flex-row pt-24  px-10 pb-4">
              <div class="myCompte w-2/12 mr-6">
                <div class="bg-white rounded-xl shadow-lg mb-6 px-6 py-4">
                  <a
                    href="./homepage"
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      dashboard
                    </span>
                    Home
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </a>
                  <a
                    href="./mycompte"
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      tune
                    </span>
                    Mon compte
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </a>
                  <a
                    href="./historiques"
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      file_copy
                    </span>
                    Historiques
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </a>
                </div>

                <div class="bg-white rounded-xl shadow-lg mb-6 px-6 py-4">
                  <a
                    href="./profil"
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      face
                    </span>
                    Profil
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </a>
                  {/* <a
                href=""
                class="inline-block text-gray-600 hover:text-black my-4 w-full"
              >
                <span class="material-icons-outlined float-left pr-2">
                  settings
                </span>
                Settings
                <span class="material-icons-outlined float-right">
                  keyboard_arrow_right
                </span>
              </a> */}
                  <button
                    onClick={handleLogout}
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      power_settings_new
                    </span>
                    Déconnexion
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </button>
                </div>
              </div>

              <div className="px-4 sm:px-6 lg:px-8 w-full">
                <div className="sm:flex sm:items-center">
                  <div className="sm:flex-auto">
                    <h1 className="text-base font-semibold leading-6  text-gray-100">
                      ABONNEMENTS
                    </h1>
                    <p className="mt-2 text-sm text-gray-100">
                      Historique de vos abonnements GoFLIX Family
                    </p>
                  </div>
                  <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <a
                      href="./homepage"
                      className="block rounded-md bg-pink-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Tableau de bord &rarr;
                    </a>
                  </div>
                </div>
                <div className="mt-8 flow-root">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                        <table
                          id="example"
                          className="min-w-full divide-y divide-gray-300 pt-4 "
                        >
                          <thead className="bg-gray-50 ">
                            <tr className="mt-8">
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Pseudo
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Type d'abonnement
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Email
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Status paiement
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Date de souscription
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Date d'expiration
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {abonnementsData.map((abData) => (
                              <tr key={abData.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {abData.pseudo}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {abData.abDure + " mois "}
                                  {abData.forme}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {abData.userEmail}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {abData.paymentStatus + " | "}{" "}
                                  {abData.montant}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {abData.createdAt}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <p className="hover:text-pink-500 hover:bg-transparent text-gray-100 bg-pink-500 rounded-lg border-2 border-pink-500 p-2">
                                    {new Date(
                                      parseInt(abData.ExpireDate)
                                    ).toLocaleString("fr-FR", {
                                      timeZoneName: "short",
                                    })}
                                  </p>
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  <button
                                    onClick={() => setCompteId(abData.id)}
                                    className="hover:text-pink-500 hover:bg-transparent text-gray-100 bg-yellow-500 rounded-lg border-2 border-transparent p-2"
                                  >
                                    Voir +
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-center text-gray-100 bg-pink-500 font-medium ">
            &copy;2023 CopyRight. Tout droit réservé GoFLIX.
          </div>
          {showModal ? (
            <>
              <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                <div className="relative w-auto my-6 mx-auto w-full">
                  {/*content*/}
                  <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}

                    {/*body*/}

                    <div
                      id="works"
                      class="relative bg-blue-800 py-10 sm:py-16  lg:py-24"
                    >
                      <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                        <section class="py-10 bg-gray-50 ">
                          <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                            <button
                              className="p-1 ml-auto  border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                              onClick={() => close()}
                            >
                              ❌
                              {/* <span className=" text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ❌
                    </span> */}
                            </button>
                            <div class="max-w-2xl mx-auto text-center">
                              <h2 class="myT text-3xl font-bold mt-16 leading-tight text-black sm:text-4xl lg:text-3xl">
                                INFORMATIONS DE L'ABONNEMENT
                              </h2>
                            </div>
                            <div class="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
                              <div class="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50">
                                <button
                                  type="button"
                                  id="question1"
                                  data-state="closed"
                                  class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                                >
                                  <span class="flex text-lg font-semibold text-black">
                                    IDENTIFIANTS DE CONNEXION NETFLIX
                                  </span>
                                  <svg
                                    id="arrow1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    class="w-6 h-6 text-gray-400"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M19 9l-7 7-7-7"
                                    ></path>
                                  </svg>
                                </button>
                                <div
                                  id="answer1"
                                  class="px-4 pb-5 text-gray-700 sm:px-6 sm:pb-6"
                                >
                                  <p>Lien: www.netflix.com</p>
                                  <p>E-mail: {flixE}</p>
                                  <p>Mot de passe : {flixP}</p>
                                </div>
                              </div>
                              <div class="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50">
                                <button
                                  type="button"
                                  id="question2"
                                  data-state="closed"
                                  class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                                >
                                  <span class="flex text-lg font-semibold text-black">
                                    ACCESS PROFIL MEMBRE GoFLIX
                                  </span>
                                  <svg
                                    id="arrow2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    class="w-6 h-6 text-gray-400"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M19 9l-7 7-7-7"
                                    ></path>
                                  </svg>
                                </button>
                                <div
                                  id="answer2"
                                  class="px-4 pb-5 sm:px-6 sm:pb-6 text-gray-700"
                                >
                                  <p>Nom du profil: {profilN}</p>
                                  <p>Mot de passe: {profilP}</p>
                                </div>
                              </div>
                              <div class="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50">
                                <button
                                  type="button"
                                  id="question3"
                                  data-state="closed"
                                  class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                                >
                                  <span class="flex text-lg font-semibold text-black">
                                    Validité de votre abonnement
                                  </span>
                                  <svg
                                    id="arrow3"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                    class="w-6 h-6 text-gray-400"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M19 9l-7 7-7-7"
                                    ></path>
                                  </svg>
                                </button>
                                <div
                                  id="answer3"
                                  class="px-4 pb-5 sm:px-6 sm:pb-6 text-pink-500 font-bold"
                                >
                                  <p className="text-gray-500">
                                    Type d'abonnement: {formy}
                                  </p>
                                  <p className="text-gray-500">
                                    Durée: {dure + " mois"}
                                  </p>

                                  <p>
                                    Fin de votre abonnement en cours: {flixExp}
                                  </p>
                                </div>
                              </div>
                              {/* <div class="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50">
                  <button
                    type="button"
                    id="question4"
                    data-state="closed"
                    class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                  >
                    <span class="flex text-lg font-semibold text-black">
                      Puis-je résilier mon abonnement à tout moment ?
                    </span>
                    <svg
                      id="arrow4"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="w-6 h-6 text-gray-400"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </button>
                  <div
                    id="answer4"
                    class="px-4 pb-5 sm:px-6 sm:pb-6 text-gray-500"
                  >
                    <p>
                      Oui, vous pouvez annuler votre abonnement à tout moment
                      sans frais cachés. Nous croyons qu'il est important
                      d'offrir une expérience sans tracas à nos utilisateurs.
                    </p>
                  </div>
                </div> */}
                            </div>

                            <p class="text-center text-gray-600 textbase mt-9">
                              Avez-vous des questions ?
                              <span class="cursor-pointer font-medium text-tertiary transition-all duration-200 hover:text-tertiary focus:text-tertiary hover-underline">
                                Contactez notre service d'assistance
                              </span>
                            </p>
                          </div>
                        </section>
                      </div>
                      <div class="absolute inset-0 m-auto max-w-xs h-[357px] blur-[118px] sm:max-w-md md:max-w-lg"></div>
                    </div>

                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                      <button
                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => close()}
                      >
                        Fermer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
        </div>
      )}
    </>
  );
}

export default Historiques;
