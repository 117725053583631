import { useRef, useState } from "react";


export const Sidebar = () => {
  const [open, setOpen] = useState(false);
  const ref = useRef(null);
 function useClickAway(){
   setOpen(false);
 };

 const toggleSidebar = () => setOpen((prev) => !prev);

   const handleLogout = () => {
     // clear the token from local storage and set the user as logged out
     // localStorage.removeItem("uuid");
     localStorage.clear();
     window.location.href = "../login";
     return null;
   };

  return (
    <>
      <button
        onClick={toggleSidebar}
        className="p-3 border-2 border-gray-100 ml-64 mt-8 hover:border-orange-300 hover:text-orange-300 rounded-xl"
        aria-label="toggle sidebar"
      >
        Menu &rarr;
        {/* <GiHamburgerMenu /> */}
      </button>
      {open && (
        <>
          <div
            aria-hidden="true"
            className="fixed bottom-0 left-0 right-0 top-0 z-40 bg-[rgba(0,0,0,0.1)] backdrop-blur-sm"
          ></div>
          <div
            className="fixed top-0 bottom-0 left-0 z-50 w-full h-screen max-w-xs border-r-2 border-zinc-800 bg-zinc-900"
            ref={ref}
            aria-label="Sidebar"
          >
            <div className="flex items-center justify-between mt-16 p-5 border-b-2 border-zinc-800">
              <span className="text-pink-500 font-bold hover:text-white">
                GoFLIX .
              </span>
              <button
                onClick={toggleSidebar}
                className="p-3 border-2 border-zinc-800 rounded-xl"
                aria-label="close sidebar"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill="currentColor"
                    d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07m1.41-1.41A8 8 0 1 0 15.66 4.34A8 8 0 0 0 4.34 15.66m9.9-8.49L11.41 10l2.83 2.83l-1.41 1.41L10 11.41l-2.83 2.83l-1.41-1.41L8.59 10L5.76 7.17l1.41-1.41L10 8.59l2.83-2.83z"
                  />
                </svg>
              </button>
            </div>
            <ul>
              <li>
                <a
                  onClick={toggleSidebar}
                  href="./homepage"
                  className="flex items-center justify-between gap-5 p-5 transition-all border-b-2 hover:bg-zinc-900 border-zinc-800"
                >
                  <span>Accueil</span>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 15 15"
                    >
                      <path
                        fill="currentColor"
                        d="M7.825.12a.5.5 0 0 0-.65 0L0 6.27v7.23A1.5 1.5 0 0 0 1.5 15h4a.5.5 0 0 0 .5-.5v-3a1.5 1.5 0 0 1 3 0v3a.5.5 0 0 0 .5.5h4a1.5 1.5 0 0 0 1.5-1.5V6.27z"
                      />
                    </svg>
                  </div>
                </a>
              </li>
              <li>
                <a
                  onClick={toggleSidebar}
                  href="./mycompte"
                  className="flex items-center justify-between gap-5 p-5 transition-all border-b-2 hover:bg-zinc-900 border-zinc-800"
                >
                  <span>Mon compte</span>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill="currentColor"
                        fill-rule="evenodd"
                        d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5M6 6a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1z"
                      />
                    </svg>
                  </div>
                </a>
              </li>
              <li>
                <a
                  onClick={toggleSidebar}
                  href="./historiques"
                  className="flex items-center justify-between gap-5 p-5 transition-all border-b-2 hover:bg-zinc-900 border-zinc-800"
                >
                  <span>Mes abonnements</span>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 56 56"
                    >
                      <path
                        fill="currentColor"
                        d="M42.624 45.003h1.3c4.54 0 6.81-2.314 6.81-6.92V16.838h-13.07c-2.291 0-3.592-1.3-3.592-3.593V0H22.634c-4.54 0-6.831 2.314-6.831 6.92V8h2.864c3.108 0 5.598.882 8.067 3.35L39.23 23.89c2.49 2.49 3.394 5.047 3.394 8.044Zm-4.519-30.92h11.57c-.131-.706-.594-1.323-1.365-2.094l-9.39-9.565c-.75-.771-1.41-1.256-2.094-1.366l.022 11.746c0 .904.353 1.279 1.257 1.279M11.505 56h21.29c4.54 0 6.831-2.314 6.831-6.92V31.339H23.67c-2.843 0-4.165-1.344-4.165-4.165V10.997h-8c-4.54 0-6.832 2.314-6.832 6.92V49.08c0 4.628 2.292 6.92 6.832 6.92m12.143-27.68h14.81c-.22-.86-.815-1.653-1.807-2.667l-11.46-11.658c-.97-.992-1.83-1.587-2.667-1.83v15.03c0 .75.375 1.125 1.124 1.125"
                      />
                    </svg>
                  </div>
                </a>
              </li>
              {/* <li>
                <a
                  onClick={toggleSidebar}
                  href="./profil"
                  className="flex items-center justify-between gap-5 p-5 transition-all border-b-2 hover:bg-zinc-900 border-zinc-800"
                >
                  <span>Profil</span>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2m0 6.39A9.973 9.973 0 0 0 17.42 10c.78 0 1.53-.09 2.25-.26c.21.71.33 1.47.33 2.26c0 4.41-3.59 8-8 8c-3 0-5.61-1.66-7-4.11L6.75 14v-1A1.25 1.25 0 0 1 8 11.75A1.25 1.25 0 0 1 9.25 13v1H12m4-2.25A1.25 1.25 0 0 0 14.75 13A1.25 1.25 0 0 0 16 14.25A1.25 1.25 0 0 0 17.25 13A1.25 1.25 0 0 0 16 11.75"
                      />
                    </svg>
                  </div>
                </a>
              </li> */}
              <li>
                <a
                  onClick={handleLogout}
                  className="flex items-center justify-between gap-5 p-5 transition-all border-b-2 hover:bg-zinc-900 border-zinc-800"
                >
                  <span>Déconnexion</span>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        fill-rule="evenodd"
                        d="M16.125 12a.75.75 0 0 0-.75-.75H4.402l1.961-1.68a.75.75 0 1 0-.976-1.14l-3.5 3a.75.75 0 0 0 0 1.14l3.5 3a.75.75 0 1 0 .976-1.14l-1.96-1.68h10.972a.75.75 0 0 0 .75-.75"
                        clip-rule="evenodd"
                      />
                      <path
                        fill="currentColor"
                        d="M9.375 8c0 .702 0 1.053.169 1.306a1 1 0 0 0 .275.275c.253.169.604.169 1.306.169h4.25a2.25 2.25 0 0 1 0 4.5h-4.25c-.702 0-1.053 0-1.306.168a1 1 0 0 0-.275.276c-.169.253-.169.604-.169 1.306c0 2.828 0 4.243.879 5.121c.878.879 2.292.879 5.12.879h1c2.83 0 4.243 0 5.122-.879c.879-.878.879-2.293.879-5.121V8c0-2.828 0-4.243-.879-5.121C20.617 2 19.203 2 16.375 2h-1c-2.829 0-4.243 0-5.121.879c-.879.878-.879 2.293-.879 5.121"
                      />
                    </svg>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </>
      )}
    </>
  );
};

