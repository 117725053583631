import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import Loader from "./loader";
import "./Appdash.css";
import { Sidebar } from "./Sidebar";


function Mycompte() {
  const isAuthenticated = localStorage.getItem("uuid");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [flixE, setFlixE] = useState("");
  const [flixP, setFlixP] = useState("");
  const [profilN, setProfilN] = useState("");
  const [notifications, setNotifications] = useState("");
  const [profilP, setProfilP] = useState("");
  const [flixExp, setFlixExp] = useState("");
  const [abonnementsData, setAbonnementsData] = useState([]);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("uuid") !== null
  );
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  // SPINNER
  setTimeout(() => {
    setIsLoading(false);
  }, 3000);
  // SPINNER

  useEffect(() => {
    setTimeout(() => {
      // Update Notifications
      const data = JSON.stringify({
        notify: "0",
      });
      let userID = atob(localStorage.getItem("isLoggedIn"));
      axios
        .patch(
          process.env.React_App_base_url + `users/notify/` + userID,
          data,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Content-Type": "application/json",
              Authorization: `Bearer ${isAuthenticated}`,
            },
          }
        )
        .then((res) => {
          // console.log(res);
        });

      // Update Notifications
      if (isAuthenticated) {
        let StateId = atob(localStorage.getItem("isLoggedIn"));
        // console.log(StateId, isAuthenticated);

        if (StateId) {
          axios
            .get(process.env.React_App_base_url + `users/` + StateId, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Content-Type": "application/json",
                Authorization: `Bearer ${isAuthenticated}`,
              },
            })
            .then((res) => {
              //  console.log(res.data.data);
              const userData = res.data.data;
              setUserName(userData.pseudo);
              setEmail(userData.email);
              setFlixE(userData.flixEmail);
              setFlixP(userData.flixPass);
              setProfilN(userData.profilName);
              setProfilP(userData.profilPass);
              setFlixExp(userData.ExpireDate);
              setNotifications(userData.notify);
              //  console.log(res.dada.data.pseudo)
              let ActivationCompte = res.data.data.isActive;

              if (ActivationCompte !== true) {
                navigate(".././login");
              }

              //console.log(res.data);
              // let getOpt = res.data.NewData.codeOtp;
              // console.log(getOpt);
            });
        }
      }
    }, 1000);
  }, []);

  //  User inactivity

  function Userinactivity() {
    var time;
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(handleLogout, 100000000);
    }
  }

  Userinactivity();

  //Check if user Login
  let TokenStatic = "HDHYI67!UY~@LJHF9H9W6L4";

  //Check if user Login

  if (!isAuthenticated) {
    navigate("../login", { replace: true });
  }

  if (isAuthenticated) {
    let CheckUserlogged = localStorage.getItem("reactjs");

    if (atob(CheckUserlogged) !== TokenStatic) {
      localStorage.clear();
      navigate("../login", { replace: true });
    }
  }

  function checkUserExist() {
    if (!loggedIn) {
      // if the user is not logged in, redirect to the login page
      window.location.href = "../login";
      return null;
    }
  }

  useEffect(() => {
    checkUserExist();
  }, []);
  //Check if user Login

  const handleLogout = () => {
    // clear the token from local storage and set the user as logged out
    // localStorage.removeItem("uuid");
    localStorage.clear();
    setLoggedIn(false);
    window.location.href = "../login";
    return null;
  };
  useEffect(() => {
   
      // GET ALL AB FOR OTHER

      axios
        .get(process.env.React_App_base_url + `abonnements/`, {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": true,
            "Content-Type": "application/json",
            Authorization: `Bearer ${isAuthenticated}`,
          },
        })
        .then((res) => {
          if (res) {
            //  console.log(res);
            const userData = res.data.payment;
            //  console.log(userData);

            //  DataFilter;

            const filteredAbonnements = userData.filter(
              (userData) =>
                userData["userEmail"] === email &&
                userData["paymentStatus"] === "confirmed" &&
                userData["forme"] === "other"
            );

            //  console.log(filteredAbonnements);

            if (filteredAbonnements.length === 0) {
              setMessage("Vous n'avez pas d'autres abonnement(s)");
            }
            if (filteredAbonnements.length <= 9) {
              setAbonnementsData(filteredAbonnements);
              setMessage(
                "Vous avez : 0" +
                  filteredAbonnements.length +
                  " autre(s) abonnement(s) pour la famille. "
              );
            }

            if (filteredAbonnements.length > 9) {
              setAbonnementsData(filteredAbonnements);
              setMessage(
                "Vous avez : " +
                  filteredAbonnements.length +
                  " autre(s) abonnements pour la famille. "
              );
            }
          }
        });

      // GET ALL AB FOR OTHER
    
  });
  return (
    <>
      {isLoading ? (
        <div className="mx-auto my-auto w-24">
          <Loader />
        </div>
      ) : (
        <div className="bg-gray-900 text-gray-100">
          <link
            href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp"
            rel="stylesheet"
          />

          <div class="bg-gray-900 min-h-screen">
            <div class="fixed bg-white text-blue-800 px-10 py-1 z-10 w-full">
              <div class="flex items-center justify-between py-2 text-5x1">
                <div class="font-bold text-blue-900 text-xl">
                  <span className="hover:text-gray-900 text-4xl font-bold text-pink-500">
                    {" "}
                    <a href="./administrator">GoFLIX .</a>
                  </span>
                  {/* <span class="text-gray-900 mx-auto text-center">Tableau de Bord</span> */}
                </div>
                <div class="flex items-center text-gray-500">
                  {/* <span class="material-icons-outlined p-2">search</span> */}
                  <a
                    href="./mycompte"
                    title="Voir les identifiants Netflix reçus"
                    class="material-icons-outlined p-2"
                  >
                    notifications{" "}
                  </a>
                  <div class="inline-flex items-center -ml-4 px-1.5 py-0.5 border-2 border-white rounded-full text-xs font-semibold leading-4 bg-red-500 text-white">
                    {notifications}
                  </div>
                  <img
                    className="bg-center bg-cover bg-no-repeat rounded-full border-2 inline-block h-12 w-12 ml-2"
                    src=".././enfant _digital.jpeg"
                    alt=""
                  />
                  <span class="">{userName}</span>
                </div>
              </div>
            </div>

            <div class="  flex flex-row pt-24  px-10 pb-4">
              <div class="myCompte w-2/12 mr-6">
                <div class="bg-white rounded-xl shadow-lg mb-6 px-6 py-4">
                  <a
                    href="./homepage"
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      dashboard
                    </span>
                    Home
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </a>
                  <a
                    href="./mycompte"
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      tune
                    </span>
                    Mon compte
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </a>
                  <a
                    href="./historiques"
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      file_copy
                    </span>
                    Historiques
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </a>
                </div>

                <div class="bg-white rounded-xl shadow-lg mb-6 px-6 py-4">
                  <a
                    href="./profil"
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      face
                    </span>
                    Profil
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </a>
                  {/* <a
                href=""
                class="inline-block text-gray-600 hover:text-black my-4 w-full"
              >
                <span class="material-icons-outlined float-left pr-2">
                  settings
                </span>
                Settings
                <span class="material-icons-outlined float-right">
                  keyboard_arrow_right
                </span>
              </a> */}
                  <button
                    onClick={handleLogout}
                    class="inline-block text-gray-600 hover:text-black my-4 w-full"
                  >
                    <span class="material-icons-outlined float-left pr-2">
                      power_settings_new
                    </span>
                    Déconnexion
                    <span class="material-icons-outlined float-right">
                      keyboard_arrow_right
                    </span>
                  </button>
                </div>
              </div>

              <section class="py-10 bg-gray-50 sm:py-16 lg:py-24">
                <div class="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                  <div class="max-w-2xl mx-auto text-center">
                    <h2 class="Betext text-3xl font-bold -mt-16 leading-tight text-black sm:text-4xl lg:text-3xl">
                      INFORMATIONS DU COMPTE PERSONNEL
                    </h2>
                  </div>
                  <div class="max-w-3xl mx-auto mt-8 space-y-4 md:mt-16">
                    <div class="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50">
                      <button
                        type="button"
                        id="question1"
                        data-state="closed"
                        class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                      >
                        <span class="flex text-lg font-semibold text-black">
                          IDENTIFIANTS DE CONNEXION NETFLIX
                        </span>
                        <svg
                          id="arrow1"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          class="w-6 h-6 text-gray-400"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 9l-7 7-7-7"
                          ></path>
                        </svg>
                      </button>
                      <div
                        id="answer1"
                        class="px-4 pb-5 text-gray-700 sm:px-6 sm:pb-6"
                      >
                        <p>Lien: www.netflix.com</p>
                        <p>E-mail: {flixE}</p>
                        <p>Mot de passe : {flixP}</p>
                      </div>
                    </div>
                    <div class="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50">
                      <button
                        type="button"
                        id="question2"
                        data-state="closed"
                        class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                      >
                        <span class="flex text-lg font-semibold text-black">
                          ACCESS PROFIL MEMBRE GoFLIX
                        </span>
                        <svg
                          id="arrow2"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          class="w-6 h-6 text-gray-400"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 9l-7 7-7-7"
                          ></path>
                        </svg>
                      </button>
                      <div
                        id="answer2"
                        class="px-4 pb-5 sm:px-6 sm:pb-6 text-gray-700"
                      >
                        <p>Nom du profil: {profilN}</p>
                        <p>Mot de passe: {profilP}</p>
                      </div>
                    </div>
                    <div class="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50">
                      <button
                        type="button"
                        id="question3"
                        data-state="closed"
                        class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                      >
                        <span class="flex text-lg font-semibold text-black">
                          Validité de votre abonnement
                        </span>
                        <svg
                          id="arrow3"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          class="w-6 h-6 text-gray-400"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 9l-7 7-7-7"
                          ></path>
                        </svg>
                      </button>
                      <div
                        id="answer3"
                        class="px-4 pb-5 sm:px-6 sm:pb-6 text-pink-500 font-bold"
                      >
                        {/* <p>Durée de validité: 30 jours</p> */}
                        <p>
                          Fin de votre abonnement en cours:{" "}
                          {new Date(Number(flixExp)).toLocaleString("fr-FR", {
                            timeZoneName: "short",
                          })}
                        </p>
                      </div>
                    </div>
                    {/* <div class="transition-all duration-200 bg-white border border-gray-200 shadow-lg cursor-pointer hover:bg-gray-50">
                  <button
                    type="button"
                    id="question4"
                    data-state="closed"
                    class="flex items-center justify-between w-full px-4 py-5 sm:p-6"
                  >
                    <span class="flex text-lg font-semibold text-black">
                      Puis-je résilier mon abonnement à tout moment ?
                    </span>
                    <svg
                      id="arrow4"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="w-6 h-6 text-gray-400"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </button>
                  <div
                    id="answer4"
                    class="px-4 pb-5 sm:px-6 sm:pb-6 text-gray-500"
                  >
                    <p>
                      Oui, vous pouvez annuler votre abonnement à tout moment
                      sans frais cachés. Nous croyons qu'il est important
                      d'offrir une expérience sans tracas à nos utilisateurs.
                    </p>
                  </div>
                </div> */}
                  </div>

                  <p class="text-center text-gray-600 textbase mt-9">
                    Avez-vous des questions ?
                    <span class="cursor-pointer font-medium text-tertiary transition-all duration-200 hover:text-tertiary focus:text-tertiary hover-underline">
                      Contactez notre service d'assistance
                    </span>
                  </p>
                </div>
              </section>
              <div class="myMenu overflow-y-auto h-96 ... ml-4 w-2/5">
                <div class="bg-white">
                  <div class="max-w-screen-md mx-auto px-4 sm:px-6 lg:px-8 flex flex-col justify-between">
                    <div class="text-center">
                      <p class="mt-4 text-sm leading-7 font-bold  text-gray-500 font-regular">
                        GoFLIX
                      </p>
                      <h3 class="text-3xl sm:text-3xl leading-normal font-extrabold tracking-tight text-gray-900">
                        Autres <span class="text-pink-500">Abonnements</span>
                      </h3>
                    </div>
                    <div className="text-green-800 p-2 mx-auto  text-center">
                      {message}
                    </div>
                    <div class="mt-8 ">
                      <ul class="">
                        <li class="text-left mb-10">
                          <div class="flex flex-row items-start">
                            <div class="flex flex-col items-center justify-center mr-5">
                              <div class="flex items-center justify-center h-20 w-20 rounded-full bg-pink-500 text-white border-4 border-white text-xl font-semibold">
                                💻
                              </div>
                              <span class="text-gray-500">IDENTIFIANTS</span>
                            </div>
                            <div class="bg-gray-100 p-5 w-full pb-10 mb-4  ">
                              <h4 class="text-lg leading-6 font-semibold text-gray-900"></h4>
                              {abonnementsData.map((abData) => (
                                <p
                                  key={abData.id}
                                  class="mt-2 text-base leading-6 text-justify text-gray-500 border-b-8 rounded-lg border-gray-800"
                                >
                                  Lien: www.netflix.com
                                  <br />
                                  E-mail: {abData.flixEmail} <br />
                                  Mot de passe : {abData.flixPass} <br />
                                  Nom du profil: {abData.profilName} <br />
                                  Mot de passe: {abData.profilPass}
                                  <br />
                                  Fin de l'abonnement :
                                  {" " +
                                    new Date(
                                      Number(abData.ExpireDate)
                                    ).toLocaleString("fr-FR", {
                                      timeZoneName: "short",
                                    })}
                                </p>
                              ))}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center text-gray-100 bg-pink-500 font-medium ">
            <span className="MenuSide fixed text-pink-500  -mt-96 ">
              <Sidebar />
            </span>
            &copy;2023 CopyRight. Tout droit réservé GoFLIX.
          </div>
        </div>
      )}
    </>
  );
}

export default Mycompte;
