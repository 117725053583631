// Filename - loader.js

import { TailSpin } from "react-loader-spinner";
const LoaderComp = () => {
  return (
    <TailSpin
      height="80"
      width="80"
      color="#ec4899"
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
    />
  );
};
export default LoaderComp;
