import { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useEffect } from "react";
import "jquery/dist/jquery.min.js";
import { TailSpin } from "react-loader-spinner";
import Loader from "./loader";
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

function Administrator() {
  const isAuthenticated = localStorage.getItem("uuid");
  const userID = atob(localStorage.getItem("isLoggedIn"));
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [msg, setMsg] = useState("");
  const [msg1, setMsg1] = useState("");
  const [allusers, setAllusers] = useState("");
  const [allcommande, setAllcommande] = useState("");
  const [totalPay, setTotalPay] = useState();
  const [listUsers, setListUsers] = useState([]);
  const [userId, setUserId] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const [notifications, setNotifications] = useState("");

  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("uuid") !== null
  );

  const navigate = useNavigate();

  // SPINNER
  setTimeout(() => {
    setIsLoading(false);
  }, 2000);
  // SPINNER

  

  useEffect(() => {
    setTimeout(() => {
      if (isAuthenticated) {
        let StateId = atob(localStorage.getItem("isLoggedIn"));
        // console.log(StateId, isAuthenticated);

        // Get all users

        axios
          .get(process.env.React_App_base_url + `users/`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Content-Type": "application/json",
              Authorization: `Bearer ${isAuthenticated}`,
            },
          })
          .then((res) => {
            //  console.log(res.data.data);
            let DataUsers = res.data.users;
            setListUsers(DataUsers);
            const usersData = res.data.users.length;
            if (usersData <= 9) {
              setAllusers("0" + usersData);
            }

            if (usersData > 9) {
              setAllusers(usersData);
            }

            //  console.log(userData.length);
          });

        // Get All user

        // Get All Abonnements

        axios
          .get(process.env.React_App_base_url + `abonnements/`, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Credentials": true,
              "Content-Type": "application/json",
              Authorization: `Bearer ${isAuthenticated}`,
            },
          })
          .then((res) => {
            // console.log(res);
            let AbonnementsDAta = res.data.payment;

            const filteredAbonnements = AbonnementsDAta.filter(
              (AbonnementsDAta) =>
                AbonnementsDAta["paymentStatus"] === "confirmed"
            );

            let all_Commande = filteredAbonnements.length;

            if (all_Commande <= 9) {
              setAllcommande("0" + all_Commande);
            }
            if (all_Commande > 9) {
              setAllcommande(all_Commande);
            }

            // Get Total Payment
            let availableConfirm = filteredAbonnements
              .filter((confirm) => confirm["paymentStatus"] === "confirmed")
              .map((item) => item.montant)
              .reduce((a, b) => a + b, 0);

            setTotalPay(availableConfirm);
          });

        // Get all Abonnements

        if (StateId) {
          axios
            .get(process.env.React_App_base_url + `users/` + StateId, {
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Credentials": true,
                "Content-Type": "application/json",
                Authorization: `Bearer ${isAuthenticated}`,
              },
            })
            .then((res) => {
              //  console.log(res.data.data);
              const userData = res.data.data;
              setUserName(userData.pseudo);
              setEmail(userData.email);
              setPhone(userData.phone);
              setNotifications(userData.notify);

              //  console.log(res.dada.data.pseudo)
              let ActivationCompte = res.data.data.isActive;
              let role = res.data.data.role;

              if (ActivationCompte !== true || role !== "admin") {
                navigate(".././login");
              }

              //console.log(res.data);
              // let getOpt = res.data.NewData.codeOtp;
              // console.log(getOpt);
            });
        }
      }
    }, 1000);
  }, []);

  function Userinactivity() {
    var time;
    window.onload = resetTimer;
    // DOM Events
    document.onmousemove = resetTimer;
    document.onkeypress = resetTimer;
    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(handleLogout, 100000000);
    }
  }
  Userinactivity();

  //Check if user Login
  let TokenStatic = "HDHYI67!UY~@LJHF9H9W6L4";

  //Check if user Login

  if (!isAuthenticated) {
    navigate("../login", { replace: true });
  }

  if (isAuthenticated) {
    let CheckUserlogged = localStorage.getItem("reactjs");

    if (atob(CheckUserlogged) !== TokenStatic) {
      localStorage.clear();
      navigate("../login", { replace: true });
    }
  }

  function checkUserExist() {
    if (!loggedIn) {
      // if the user is not logged in, redirect to the login page
      window.location.href = "../login";
      return null;
    }
  }
  useEffect(() => {
    checkUserExist();
  }, []);
  //Check if user Login

  const handleLogout = () => {
    // clear the token from local storage and set the user as logged out
    // localStorage.removeItem("uuid");
    localStorage.clear();
    setLoggedIn(false);
    window.location.href = "../login";
    return null;
  };

  //JSdataTable
  $(document).ready(function () {
    setTimeout(function () {
      $("#example").DataTable({
        paging: true,
        searching: true,
        retrieve: true,
        language: {
          processing: "Traitement en cours...",
          search: "Recherche&nbsp;:",
          lengthMenu: "Afficher _MENU_ &eacute;l&eacute;ments",
          info: "Affichage de l'&eacute;lement _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
          infoEmpty:
            "Affichage de l'&eacute;lement 0 &agrave; 0 sur 0 &eacute;l&eacute;ments",
          infoFiltered:
            "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
          infoPostFix: "",
          loadingRecords: "Chargement en cours...",
          zeroRecords: "Aucun &eacute;l&eacute;ment &agrave; afficher",
          emptyTable: "Aucune donnée disponible dans le tableau",
          paginate: {
            first: "Premier",
            previous: "Pr&eacute;c&eacute;dent",
            next: "Suivant",
            last: "Dernier",
          },
        },
      });
    }, 3000);
  }, []);

  if (userId !== "") {
    // console.log(userId);
    let CommandeID = btoa(userId);
    localStorage.setItem("isReact", CommandeID);

    navigate("../dash/updatecompte");
  }

  return (
    <>
      {isLoading ? (
        <div className="mx-auto my-auto w-24">
          <Loader />
        </div>
      ) : (
        <div className="bg-gray-900 text-gray-100">
          <link
            href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp"
            rel="stylesheet"
          />
          <div>
            <div class="bg-gray-900 min-h-screen">
              <div class="fixed bg-white text-blue-800 px-10 py-1 z-10 w-full">
                <div class="flex items-center justify-between py-2 text-5x1">
                  <div class="font-bold text-blue-900 text-xl">
                    <span className="hover:text-gray-900 text-4xl font-bold text-pink-500">
                      {" "}
                      GoFLIX .
                    </span>
                    {/* <span class="text-gray-900 mx-auto text-center">Tableau de Bord</span> */}
                  </div>
                  <div class="flex items-center text-gray-500">
                    {/* <span class="material-icons-outlined p-2">search</span> */}
                    {/* <span class="material-icons-outlined p-2">notifications</span> */}
                    <img
                      className="bg-center bg-cover bg-no-repeat rounded-full border-2 inline-block h-12 w-12 ml-2"
                      src=".././enfant _digital.jpeg"
                      alt=""
                    />
                    <span class="">{userName}</span>
                  </div>
                </div>
              </div>

              <div class="flex flex-row pt-24  px-10 pb-4">
                <div class="w-2/12 mr-6">
                  <div class="bg-white rounded-xl shadow-lg mb-6 px-6 py-4">
                    <a
                      href="./administrator"
                      class="inline-block text-gray-600 hover:text-black my-4 w-full"
                    >
                      <span class="material-icons-outlined float-left pr-2">
                        dashboard
                      </span>
                      Home
                      <span class="material-icons-outlined float-right">
                        keyboard_arrow_right
                      </span>
                    </a>
                    {/* <a
                href="./mycompte"
                class="inline-block text-gray-600 hover:text-black my-4 w-full"
              >
                <span class="material-icons-outlined float-left pr-2">
                  tune
                </span>
                Mon compte
                <span class="material-icons-outlined float-right">
                  keyboard_arrow_right
                </span>
              </a> */}
                    <a
                      href="./commande"
                      class="inline-block text-gray-600 hover:text-black my-4 w-full"
                    >
                      <span class="material-icons-outlined float-left pr-2">
                        file_copy
                      </span>
                      Commande
                      <span class="material-icons-outlined float-right">
                        keyboard_arrow_right
                      </span>
                    </a>
                  </div>

                  <div class="bg-white rounded-xl shadow-lg mb-6 px-6 py-4">
                    {/* <a
                href="./profil"
                class="inline-block text-gray-600 hover:text-black my-4 w-full"
              >
                <span class="material-icons-outlined float-left pr-2">
                  face
                </span>
                Profil
                <span class="material-icons-outlined float-right">
                  keyboard_arrow_right
                </span>
              </a> */}
                    {/* <a
                href=""
                class="inline-block text-gray-600 hover:text-black my-4 w-full"
              >
                <span class="material-icons-outlined float-left pr-2">
                  settings
                </span>
                Settings
                <span class="material-icons-outlined float-right">
                  keyboard_arrow_right
                </span>
              </a> */}
                    <button
                      onClick={handleLogout}
                      class="inline-block text-gray-600 hover:text-black my-4 w-full"
                    >
                      <span class="material-icons-outlined float-left pr-2">
                        power_settings_new
                      </span>
                      Déconnexion
                      <span class="material-icons-outlined float-right">
                        keyboard_arrow_right
                      </span>
                    </button>
                  </div>
                </div>

                <div className="px-4 sm:px-6 lg:px-8 w-full">
                  <div class="m-6">
                    <div class="flex flex-wrap -mx-6">
                      <div class="w-full px-6 sm:w-1/2 xl:w-1/3">
                        <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-slate-100">
                          <div class="p-3 rounded-full bg-indigo-600 bg-opacity-75">
                            <svg
                              class="h-8 w-8 text-white"
                              viewBox="0 0 28 30"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18.2 9.08889C18.2 11.5373 16.3196 13.5222 14 13.5222C11.6804 13.5222 9.79999 11.5373 9.79999 9.08889C9.79999 6.64043 11.6804 4.65556 14 4.65556C16.3196 4.65556 18.2 6.64043 18.2 9.08889Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M25.2 12.0444C25.2 13.6768 23.9464 15 22.4 15C20.8536 15 19.6 13.6768 19.6 12.0444C19.6 10.4121 20.8536 9.08889 22.4 9.08889C23.9464 9.08889 25.2 10.4121 25.2 12.0444Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M19.6 22.3889C19.6 19.1243 17.0927 16.4778 14 16.4778C10.9072 16.4778 8.39999 19.1243 8.39999 22.3889V26.8222H19.6V22.3889Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M8.39999 12.0444C8.39999 13.6768 7.14639 15 5.59999 15C4.05359 15 2.79999 13.6768 2.79999 12.0444C2.79999 10.4121 4.05359 9.08889 5.59999 9.08889C7.14639 9.08889 8.39999 10.4121 8.39999 12.0444Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M22.4 26.8222V22.3889C22.4 20.8312 22.0195 19.3671 21.351 18.0949C21.6863 18.0039 22.0378 17.9556 22.4 17.9556C24.7197 17.9556 26.6 19.9404 26.6 22.3889V26.8222H22.4Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M6.64896 18.0949C5.98058 19.3671 5.59999 20.8312 5.59999 22.3889V26.8222H1.39999V22.3889C1.39999 19.9404 3.2804 17.9556 5.59999 17.9556C5.96219 17.9556 6.31367 18.0039 6.64896 18.0949Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </div>

                          <div class="mx-5">
                            <h4 class="text-2xl font-semibold text-gray-700">
                              {allusers}
                            </h4>
                            <div class="text-gray-500">Utilisateurs</div>
                          </div>
                        </div>
                      </div>

                      <div class="w-full mt-6 px-6 sm:w-1/2 xl:w-1/3 sm:mt-0">
                        <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-slate-100">
                          <div class="p-3 rounded-full bg-orange-600 bg-opacity-75">
                            <svg
                              class="h-8 w-8 text-white"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M4.19999 1.4C3.4268 1.4 2.79999 2.02681 2.79999 2.8C2.79999 3.57319 3.4268 4.2 4.19999 4.2H5.9069L6.33468 5.91114C6.33917 5.93092 6.34409 5.95055 6.34941 5.97001L8.24953 13.5705L6.99992 14.8201C5.23602 16.584 6.48528 19.6 8.97981 19.6H21C21.7731 19.6 22.4 18.9732 22.4 18.2C22.4 17.4268 21.7731 16.8 21 16.8H8.97983L10.3798 15.4H19.6C20.1303 15.4 20.615 15.1004 20.8521 14.6261L25.0521 6.22609C25.2691 5.79212 25.246 5.27673 24.991 4.86398C24.7357 4.45123 24.2852 4.2 23.8 4.2H8.79308L8.35818 2.46044C8.20238 1.83722 7.64241 1.4 6.99999 1.4H4.19999Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M22.4 23.1C22.4 24.2598 21.4598 25.2 20.3 25.2C19.1403 25.2 18.2 24.2598 18.2 23.1C18.2 21.9402 19.1403 21 20.3 21C21.4598 21 22.4 21.9402 22.4 23.1Z"
                                fill="currentColor"
                              ></path>
                              <path
                                d="M9.1 25.2C10.2598 25.2 11.2 24.2598 11.2 23.1C11.2 21.9402 10.2598 21 9.1 21C7.9402 21 7 21.9402 7 23.1C7 24.2598 7.9402 25.2 9.1 25.2Z"
                                fill="currentColor"
                              ></path>
                            </svg>
                          </div>

                          <div class="mx-5">
                            <h4 class="text-2xl font-semibold text-gray-700">
                              {allcommande}
                            </h4>
                            <div class="text-gray-500">Total ventes</div>
                          </div>
                        </div>
                      </div>

                      <div class="w-full mt-6 px-6 sm:w-1/2 xl:w-1/3 xl:mt-0">
                        <div class="flex items-center px-5 py-6 shadow-sm rounded-md bg-slate-100">
                          <div class="p-3 rounded-full bg-pink-600 bg-opacity-75">
                            <svg
                              class="h-8 w-8 text-white"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.99998 11.2H21L22.4 23.8H5.59998L6.99998 11.2Z"
                                fill="currentColor"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linejoin="round"
                              ></path>
                              <path
                                d="M9.79999 8.4C9.79999 6.08041 11.6804 4.2 14 4.2C16.3196 4.2 18.2 6.08041 18.2 8.4V12.6C18.2 14.9197 16.3196 16.8 14 16.8C11.6804 16.8 9.79999 14.9197 9.79999 12.6V8.4Z"
                                stroke="currentColor"
                                stroke-width="2"
                              ></path>
                            </svg>
                          </div>

                          <div class="mx-5">
                            <h4 class="text-2xl font-semibold text-gray-700">
                              {totalPay}
                            </h4>
                            <div class="text-gray-500">Chiffres d'affaires</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                      <h1 className="text-base font-semibold leading-6 text-gray-100">
                        LISTE DES UTILISATEURS
                      </h1>
                      <p className="mt-2 text-sm text-gray-100">
                        GoFLIX Family
                      </p>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                      <button
                        type="button"
                        className="block rounded-md bg-pink-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                        Télécharger &rarr;
                      </button>
                    </div>
                  </div>
                  <div className="mt-8 flow-root">
                    <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                          <table
                            id="example"
                            className="min-w-full divide-y divide-gray-300 pt-4 "
                          >
                            <thead className="bg-gray-50 ">
                              <tr className="">
                                <th
                                  scope="col"
                                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                >
                                  Pseudo
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Type d'abonnement
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Email
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Numéro WhatsApp
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Rôle
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Date de souscription
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Expire
                                </th>
                                <th
                                  scope="col"
                                  className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white ">
                              {listUsers.map((userData) => (
                                <tr key={userData.id}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                    {userData.pseudo}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {userData.forme}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {userData.email}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {userData.phone}
                                  </td>
                                  <td className="whitespace-nowrap bg-orange-400 px-3 py-4 text-sm text-gray-100">
                                    {userData.role}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {userData.createdAt}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm bg-pink-500 text-gray-500">
                                    <bouton className="text-gray-100 bg-pink-500 rounded-lg border-2 border-pink-500 p-2">
                                      {new Date(
                                        parseInt(userData.ExpireDate)
                                      ).toLocaleString("fr-FR", {
                                        timeZoneName: "short",
                                      })}
                                    </bouton>
                                  </td>
                                  <td className="whitespace-nowrap px-3 text-sm text-gray-500">
                                    <button
                                      // href={`./updatecompte/?csi=${userData.id}`}
                                      onClick={() => setUserId(userData.id)}
                                      className="hover:text-green-500 hover:bg-transparent bg-green-500 text-gray-100 rounded-lg border-2 border-transparent px-2"
                                    >
                                      Editer
                                    </button>
                                    {/* <a
                                      href="./updatecompte"
                                      className="hover:text-pink-500 ml-2 hover:bg-transparent text-gray-100 bg-pink-500 rounded-lg border-2 border-pink-500 px-2"
                                    >
                                      Acitvé
                                    </a> */}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center text-gray-100 bg-pink-500 font-medium ">
              &copy;2023 CopyRight. Tout droit réservé GoFLIX.
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Administrator;
